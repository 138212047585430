@import 'styles/variable.less';

.marker-details {
  position: absolute;
  bottom: 24px;
  z-index: 1001;
  width: 100%;
  height: 414px;
  background-color: @white;
  .marker-detail-header {
    height: 46px;
    .flexAlignCenter();
    justify-content: space-between;
    padding-left: 32px;
    padding-right: 16px;
    > div:first-child,
    > div:nth-child(2) {
      color: @secondary;
      .fs-16();
      .fw-700();
    }
    > svg {
      cursor: pointer;
    }
  }
  .marker-detail-table {
    height: calc(100% - 46px);
  }
}

@primary-color: #6E3BD1;@page-header-heading-title: 24px;@btn-border-radius-base: 4px;@btn-border-radius-sm: 4px;