@import 'styles/variable.less';

.report-sidebar-card {
  width: 100%;
  .flexAlignCenter();
  padding: 10px;
  .borderBottom();
  > a {
    color: @secondary;
    .fs-14();
    .fw-600();
    line-height: 20px;
    cursor: pointer;
    text-transform: capitalize;
    &:hover {
      color: @secondary;
    }
  }
}

.editable-cell {
  position: relative;
  width: inherit;
}

.editable-cell-value-wrap {
  cursor: pointer;
  width: inherit;
  min-height: 26px;
  line-height: 26px;
  &:hover {
    border: 0.1px solid @primary;
    border-radius: 4px;
  }
}

@primary-color: #6E3BD1;@page-header-heading-title: 24px;@btn-border-radius-base: 4px;@btn-border-radius-sm: 4px;