@import 'styles/variable.less';
@import 'styles/main.less';

.main-home {
  height: 100%;
  width: 100%;
  position: relative;
  .map-container {
    height: 100%;
    width: 100%;
    outline: none;
    .leaflet-control-layers {
      opacity: 0 !important;
    }
    .round-loc-icon {
      background-color: @white;
      border-radius: 50%;
    }
  }
}
.home-map-container {
  .leaflet-control {
    position: absolute;
    top: 0;
  }
}

.search-select-home {
  width: 356px;
  height: 32px;
  direction: rtl;
  .ant-select-selector {
    direction: ltr;
    .ant-select-selection-placeholder {
      padding-inline-end: 0;
      padding-inline-start: 18px;
    }
    .ant-select-selection-search,
    .ant-select-selection-item {
      padding-inline-start: 18px;
    }
  }
}

@primary-color: #6E3BD1;@page-header-heading-title: 24px;@btn-border-radius-base: 4px;@btn-border-radius-sm: 4px;