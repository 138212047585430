@import 'styles/variable.less';

.create-new-design {
  height: 100%;
  padding: 24px 96px 24px 0;
  position: relative;
  .new-design-label {
    padding-left: 56px;
    .flexAlignCenter();
    .back-icon-btn {
      border: none;
    }
    > span {
      color: @titleColor;
      padding-left: 8px;
      .fs-22();
      .fw-600();
    }
  }
  .design-content {
    padding-left: 96px;
    .cfn-code {
      padding: 20px 0 12px;
      > span {
        color: @primary;
        font-size: 17px;
        .fw-600();
      }
    }
    .design-divider {
      margin: 0;
      margin-bottom: 32px;
      border-color: @borderclr;
    }

    .design-divider2 {
      margin: 8px 0 32px;
      border-color: @borderclr;
    }
    .design-form {
      .form-main {
        height: calc(100vh - 286px);
        margin-bottom: 24px;
        overflow: auto;
        &::-webkit-scrollbar {
          width: 5px;
          background-color: @white;
        }
        &::-webkit-scrollbar-thumb {
          background: @subheader;
          border-radius: 3px;
        }
        .input-main {
          overflow: auto;
          margin-right: 0 !important;
        }
        .form-input-main {
          width: 662px;
          border-radius: 4px;
          color: @secondary;
          @media (max-width: 1255px) {
            width: 544px;
          }
          @media (max-width: 1080px) {
            width: 504px;
          }
          @media (max-width: 1024px) {
            width: 464px;
          }
        }
      }
      .input-label {
        margin-bottom: 20px;
        > div:first-child {
          > div:first-child {
            > label {
              .fw-500();
              .fs-14();
              line-height: 19px;
              color: @secondary;
              &::before{
                display: none;
              }
            }
          }
          .form-input {
            width: 299px;
            border-radius: 4px;
            color: #696b72;
            @media (max-width: 1255px) {
              width: 240px;
            }
            @media (max-width: 1080px) {
              width: 220px;
            }
            @media (max-width: 1024px) {
              width: 200px;
            }
          }
          .form-input-select {
            > div {
              border-radius: 4px;
            }
          }
        }
      }
      > div:last-child {
        display: flex;
        justify-content: flex-end;
        .back-btn {
          width: 80px;
          border: 0.5px solid #696b72;
          color: #696b72;
          box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.15);
          border-radius: 4px;
          height: 32px;
          .flexJustifyAlignCenter();
          > span {
            .fs-14();
            .fw-500();
          }
        }
        .create-design-btn {
          width: 185px;
          margin-left: 32px;
          box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.15);
          border-radius: 4px;
          height: 32px;
          .flexJustifyAlignCenter();
          > span {
            .fs-14();
            .fw-500();
          }
        }
      }
    }
  }
}

@primary-color: #6E3BD1;@page-header-heading-title: 24px;@btn-border-radius-base: 4px;@btn-border-radius-sm: 4px;