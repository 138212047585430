@import 'styles/variable.less';
@import 'styles/main.less';

.collect-main {
  height: 100%;
  padding: 24px 16px 16px 16px;
  display: flex;
  flex-direction: column;
  background-color: @white;
  .collect-upper {
    height: 40%;
    padding-bottom: 12px;
    .collect-page-header {
      .flexAlignCenter();
      justify-content: flex-start;
      gap: 16px;
      height: 32px;
      > span:nth-child(2) {
        color: @titleColor;
        .fs-22();
        .fw-600();
      }
      > button:last-child {
        margin-left: 8px;
        border-radius: 4px;
      }
    }
    .collect-upper-content {
      padding-top: 8px;
      height: calc(100% - 35px);
      > div:first-child {
        border-bottom: 0.5px solid @borderclr;
        .collect-btn {
          border-radius: 0px;
          .fs-14();
          .fw-600();
          color: @grayshade;
          height: 32px;
          @media screen {
            @media (max-width: 1230px) {
              .fs-12();
              padding: 4px 12px;
            }
            @media (max-width: 1100px) {
              font-size: 11px !important;
              padding: 4px 9.5px;
            }
          }
        }
        .collect-active-btn {
          color: @primary;
          background-color: #f7f3ff;
          border-bottom: 3px solid @primary;
        }
      }
      /* AIRAC css */
      .main-tab-content {
        padding-top: 16px;
        height: calc(100% - 32px);
        display: flex;
        flex-direction: row;
        gap: 24px;
        @media (max-width: 1440px) {
          gap: 16px;
        }
        .version-lists {
          border: 0;
          border-right: 1px solid @borderclr;
          border-radius: 0;
          .ant-card-head {
            border-color: @grayshade;
            padding-left: 18px;
            min-height: 35px;
          }
          .ant-card-body {
            padding: 8px;
            height: 100%;
            overflow: auto;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            gap: 8px;
            padding-right: 2px;
            &::-webkit-scrollbar {
              width: 3px;
            }
            &::-webkit-scrollbar-thumb {
              background-color: #696b72;
            }
            > span {
              padding-left: 9px;
              color: #696b72;
              .fs-14();
              .fw-400();
              padding-top: 3px;
              padding-bottom: 3px;
            }
            .airac-cycle {
              cursor: pointer;
            }
            .active-cycle {
              background-color: #f7f3ff;
              color: @primary;
              .fw-600();
              cursor: pointer;
              position: relative;
              &::after {
                content: '';
                width: 3px;
                background-color: @primary;
                position: absolute;
                left: 0;
                z-index: 1;
                height: 28px;
                top: 0;
                border-radius: 0px 3px 3px 0px;
              }
            }
          }
        }
        .list-content-btn {
          width: calc(100% - 159px);
          display: flex;
          justify-content: space-between;
          flex-direction: column;
          > div:first-child {
            overflow: auto;
            // margin-bottom: 8px;
            &::-webkit-scrollbar {
              width: 3px;
            }
            &::-webkit-scrollbar-thumb {
              background-color: #696b72;
            }
          }
          .all-btn {
            height: 40px;
            display: flex;
            align-items: flex-end;
            justify-content: flex-end;
            gap: 16px;
            > button {
              border-radius: 4px;
              height: 32px;
            }
            .clear-all-btn {
              color: @primary;
            }
            .add-datastock-btn {
              width: 159px;
            }
            .select-all-btn {
              width: 99px;
            }
          }
        }
        .data-list-btn {
          width: 100%;
          // > div:first-child {
          //    overflow: hidden;
          // }
        }
      }
    }
  }
  .temperature-content {
    .temperature-inr-content {
      display: flex;
      flex-direction: column;
      gap: 8px;
      .ant-input-number-affix-wrapper {
        width: 299px;
        .ant-input-number-input {
          color: @primary;
        }
      }
      .ant-input-number-handler-wrap {
        display: none !important;
      }
    }
  }
  .temperature-inr-data {
    font-size: 14px;
    color: @secondary;
    font-weight: 500;
  }
  .collect-data-divider {
    margin: 0;
    border-block-start: 0.5px solid @borderclr;
    line-height: 0;
  }
  .magnetic-lower {
    padding-top: 12px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 24px;
    > div:first-child {
      width: 299px;
    }
    .ant-input-number-affix-wrapper {
      width: 299px;
      .ant-input-number-input {
        color: @primary;
      }
    }
    .ant-input-number-handler-wrap {
      display: none !important;
    }
    .ant-input-number-suffix {
      color: @primary;
    }
    .variation-input {
      width: 299px;
    }
    .modal-input {
      border-color: #f2ecfc;
      background-color: #f2ecfc;
      .ant-input-number-input {
        background-color: #f2ecfc;
        color: @primary;
      }
    }
    .apply-btn-main {
      width: 299px;
      margin-left: 80px;
      .apply-btn {
        width: 212px;
      }
      .magnetic-note-main {
        display: flex;
        flex-direction: column;
      }
      .magnetic-note {
        .fs-14();
        .fw-500();
        color: #95969d;
      }
    }
  }
  .collect-lower {
    height: 60%;
    display: flex;
    flex-direction: row;
    align-items: baseline;
    gap: 16px;
    padding-top: 12px;
    > div {
      width: 50%;
      height: 100%;
      display: flex;
      flex-direction: column;
      gap: 8px;
      .map-view-content,
      .table-view-content {
        display: flex;
        justify-content: flex-start;
        > span:first-child {
          color: @titleColor;
          .fs-22();
          .fw-600();
          height: 30px;
        }
        > div:last-child {
          margin-left: 16px;
          display: flex;
          cursor: pointer;
        }
      }
      > div:last-child {
        height: calc(100% - 38px);
        width: 100%;
        > div {
          border-radius: 4px;
          width: 100%;
          height: 100%;
        }
        > span {
          height: 100%;
          color: #696b72;
          .fs-16();
          .fw-600();
          line-height: normal;
        }
      }
    }
    > div:last-child {
      > div:first-child {
        width: 100%;
        height: 30px;
      }
      > div:last-child {
        height: calc(100% - 38px);
        width: 100%;
        overflow: auto;
        border: 0.5px solid @borderclr;
        border-radius: 4px;
        &::-webkit-scrollbar {
          width: 0px;
          height: 4px;
        }
        &::-webkit-scrollbar-thumb {
          background-color: #696b72;
        }
      }
      .collect-table-content {
        background: @white;
        > div {
          height: 100%;
          > div {
            height: 100%;
            > div {
              height: 100%;
              > div {
                height: 100%;
                > div {
                  height: 100%;
                }
              }
            }
          }
        }
        .collect-table-main {
          .ant-table-body {
            overflow: auto !important;
            &::-webkit-scrollbar {
              width: 6px;
            }
            &::-webkit-scrollbar-thumb {
              background-color: #696b72;
              border-radius: 3px;
            }
          }
          table {
            thead {
              tr {
                th {
                  padding: 0;
                  height: 44px;
                  border-bottom: 0;
                  background-color: #f3f3f3;
                }
              }
            }
            tbody {
              tr {
                td {
                  .fs-12();
                  .fw-500();
                  color: #696b72;
                  padding: 0;
                  height: 48px;
                  .co-ord {
                    .fs-12();
                  }
                }
              }
            }
          }
        }
      }
    }
    .collect-map-container {
      .leaflet-control {
        position: absolute;
        top: 0;
      }
    }
  }
}

.obstacle-content {
  width: 100%;
  .obst-terr-title {
    .fs-22();
    .fw-600();
    line-height: 30px;
  }
  .obstacle-grp-btn {
    .survey-btn {
      margin-inline-start: -3px;
    }
    .active-view-btn {
      border-color: @primary;
      color: @primary;
      z-index: 2;
    }
    .terrain-active {
      border-start-end-radius: 4px;
      border-end-end-radius: 4px;
    }
    .survey-active {
      border-start-start-radius: 4px;
      border-end-start-radius: 4px;
      margin-inline-start: -3px;
    }
  }
  .obs-active-btn-content {
    min-width: 300px;
    .upload-btn,
    .srtm-btn {
      .flexJustifyAlignCenter();
      padding: 0;
      color: #696b72;
      .fs-12();
      .fw-500();
      line-height: 19.12px;
      &:hover,
      &:focus,
      &:active,
      &:focus-visible,
      &:focus-within {
        background-color: unset;
      }
    }
    .upload-btn {
      .csv-file-name {
        text-overflow: ellipsis;
        max-width: 190px;
        overflow: hidden;
        margin-left: 6px;
        .fs-14();
        .fw-600();
        color: @primary;
      }
    }
    .active-view-btn {
      color: @primary;
      .fw-600();
      .fs-14();
      &:hover,
      &:focus,
      &:active,
      &:focus-visible,
      &:focus-within {
        color: @primary;
      }
    }
    .obst-info-text {
      padding-left: 23px;
      color: #696b72;
      .fs-12();
      .fw-500();
      line-height: 20px;
      > a {
        padding-left: 5px;
        .fs-10();
        .fw-500();
        line-height: 20px;
      }
    }
  }
  .tolrnc-content-text {
    .fs-14();
    .fw-500();
    line-height: 19.12px;
  }
  .tolerance-input {
    .ant-input,
    .ant-input-group-addon {
      border-color: @primary;
      color: @primary;
    }
    .ant-input {
      border-right: 0;
    }
    .ant-input-group-addon {
      background-color: @white;
      color: @primary;
    }
  }
}

/* Map FullScreen View*/
.full-map-view {
  > div {
    > div:first-child {
      padding: 24px 16px 16px;
      border-bottom: unset;
      > div {
        > button {
          padding: 0;
          margin-inline-end: 0;
        }
        > div {
          .flexAlignCenter();
          > span {
            padding-left: 16px;
            line-height: 30px;
            .fs-22();
            .fw-600();
          }
          > button {
            margin-left: 16px;
            .flexJustifyAlignCenter();
          }
        }
      }
    }
    > div:last-child {
      padding: 0 16px 24px 16px;
      position: relative;
      .collect-map-container {
        height: 100%;
        width: 100%;
        border-radius: 4px;
        .leaflet-control {
          position: absolute;
          top: 0;
        }
        > div {
          border-radius: 4px;
        }
      }
    }
  }
}
/* Table FullScreen View*/
.full-table-view {
  > div {
    > div:first-child {
      padding: 24px 16px 16px;
      border-bottom: unset;
      > div {
        > button {
          padding: 0;
          margin: 0;
        }
        > div {
          > div {
            display: flex;
            justify-content: space-between;
            > div {
              .flexAlignCenter();
              > span {
                padding-left: 16px;
                line-height: 30px;
                .fs-22();
                .fw-600();
              }
              > button {
                margin-left: 16px;
                .flexJustifyAlignCenter();
              }
            }
          }
        }
      }
    }
    > div:last-child {
      padding: 0 16px 24px 16px;
    }
  }
  .collect-table-content {
    height: 100%;
    .full-collect-table {
      height: 100%;
      border-radius: 4px;
      border: 1px solid @grayshade;
      background: @white;
      .ant-table-body {
        overflow: auto !important;
        &::-webkit-scrollbar {
          width: 3px;
        }
        &::-webkit-scrollbar-thumb {
          background-color: #696b72;
          border-radius: 3px;
        }
      }
      table {
        thead {
          tr {
            th {
              padding: 0;
              border-bottom: 0;
              height: 48px;
              background-color: #f3f3f3;
            }
          }
        }
        tbody {
          tr {
            td {
              .fs-14();
              .fw-500();
              color: #696b72;
              padding: 0;
              height: 70px;
            }
          }
        }
      }
    }
  }
  .ad-data-table-main {
    height: 100%;
    border: 0.5px solid @borderclr;
    border-radius: 4px;
    overflow: auto;
    &::-webkit-scrollbar {
      width: 3px;
      height: 3px;
    }
    &::-webkit-scrollbar-thumb {
      background-color: #696b72;
      border-radius: 3px;
    }
  }
}
.ad-no-data {
  height: 100%;
  // border: 0.5px solid @borderclr;
  border-radius: 4px;
  color: #696b72;
  font-size: 16px;
  font-weight: 600;
  line-height: normal;
}
/* Collect MagneticVariation DataTab start */
.magnetic-content {
  width: 100%;
  padding-left: 32px;
  > div:first-child {
    .magnetic-label {
      .fs-22();
      .fw-600();
      line-height: 30px;
    }
  }
  .coord-label {
    color: @secondary;
    .fs-14();
    .fw-500();
    line-height: 19px;
  }
  .empty-variation-label {
    height: 19px;
  }
  > div:nth-child(2) {
    .coord-input {
      width: 299px;
      padding: 0 8px;
      height: 32px;
      border-radius: 4px;
      color: #00000040;
    }
    .elevation-input {
      width: 299px;
      border-color: @grayshade;
      .ant-input {
        border-right: 0;
      }
      .ant-input-group-addon {
        padding: 0;
        border: 0;
        .ant-btn-group {
          border: 0;
          margin-inline-start: -3px;
        }
      }
    }
    .elevation-grp-btn {
      > button {
        height: 32px;
        width: 42px;
        border: 1px solid @primary;
        &:hover {
          border: 1px solid @primary;
        }
        > span {
          .fs-14();
          .fw-500();
          line-height: normal;
          color: @primary;
        }
        &:last-child {
          margin-inline-start: -3px;
        }
      }
      .ft-active-btn,
      .m-active-btn {
        background: @primary;
        > span {
          color: @white;
        }
      }
      .ft-active-btn {
        border-start-end-radius: 4px;
        border-end-end-radius: 4px;
        box-shadow: 2px 0px 6px 0px rgba(0, 0, 0, 0.15);
        z-index: 3;
      }
      .m-active-btn {
        border-start-start-radius: 4px;
        border-end-start-radius: 4px;
        box-shadow: -2px 0px 6px 0px rgba(0, 0, 0, 0.15);
        z-index: 2;
      }
    }
    .suggestion-label {
      color: @grayshade;
      .fs-14();
      .fw-400();
      line-height: 19px;
    }
  }
  > div:last-child {
    .modal-content-main {
      align-items: flex-start;
      .modal-content {
        width: 299px;
        .magnetic-modal {
          .ant-radio-wrapper {
            > span:last-child {
              .fs-12();
              color: @secondary;
              .fw-400();
              line-height: 16.39px;
            }
            .ant-radio-inner {
              width: 14px;
              height: 14px;
            }
          }
          .ant-radio-wrapper-checked {
            > span:last-child {
              color: @primary;
              .fw-500();
            }
          }
        }
      }
    }
    .calculate-btn {
      width: 159px;
    }
  }
}

/* Collect MagneticVariation DataTab end */

/*  Magnetic Declination Data Stock */
.variation-input {
  border-color: @grayshade;
  .ant-input-number-input {
    color: @primary;
  }
  .ant-input-number {
    border-right: 0;
    &:hover {
      z-index: 0;
    }
  }
  .ant-input-number-group-addon {
    padding: 0;
    border: 0;
    .ant-btn-group {
      border: 0;
      margin-inline-start: -3px;
    }
  }
  .variation-grp-btn {
    > button {
      height: 32px;
      width: 42px;
      border: 1px solid @primary;
      background: @white;
      &:hover {
        border: 1px solid @primary;
        background-color: @white !important;
      }
      > span {
        display: flex;
        justify-content: center;
        align-items: center;
        .fs-14();
        .fw-500();
        line-height: normal;
        color: @primary;
      }
      &:last-child {
        margin-inline-start: -3px;
      }
    }
    .w-active-btn,
    .e-active-btn {
      background: @primary;
      &:hover {
        background: @primary !important;
      }
      > span {
        color: @white;
      }
    }
    .w-active-btn {
      border-start-end-radius: 4px;
      border-end-end-radius: 4px;
      box-shadow: 2px 0px 6px 0px rgba(0, 0, 0, 0.15);
      z-index: 3;
    }
    .e-active-btn {
      border-start-start-radius: 4px;
      border-end-start-radius: 4px;
      box-shadow: -2px 0px 6px 0px rgba(0, 0, 0, 0.15);
      z-index: 2;
    }
  }
}
.table-grp-btn {
  > button {
    height: 26px;
    padding: 0;
    border: 0.5px solid @primary;
    &:first-child {
      width: 41px;
    }
    &:nth-child(2) {
      width: 62px;
      margin-inline-start: -3px;
    }
    &:last-child {
      width: 67px;
      margin-inline-start: -3px;
    }
    > span {
      .fs-14();
      .fw-500();
      line-height: 20px;
      color: @primary;
    }
  }
  .all-active-btn,
  .survey-active-btn,
  .eaip-active-btn {
    background: @primary;
    > span {
      color: @white;
    }
  }
  .all-active-btn {
    border-start-end-radius: 4px;
    border-end-end-radius: 4px;
    box-shadow: 2px 0px 6px 0px rgba(0, 0, 0, 0.15);
    z-index: 2;
  }
  .eaip-active-btn {
    border-radius: 4px;
    box-shadow: 2px 0px 6px 0px rgba(0, 0, 0, 0.15);
    z-index: 2;
  }
  .survey-active-btn {
    border-start-start-radius: 4px;
    border-end-start-radius: 4px;
    box-shadow: -2px 0px 6px 0px rgba(0, 0, 0, 0.15);
    z-index: 2;
  }
}

@primary-color: #6E3BD1;@page-header-heading-title: 24px;@btn-border-radius-base: 4px;@btn-border-radius-sm: 4px;