@import 'styles/variable.less';

.home-sub-header {
  position: absolute;
  top: 4px;
  z-index: 1001;
  width: 100%;
  // display: flex;
  // justify-content: space-between;
  // align-items: center;
  // padding: 0px 16px;
  // height: 72px;
}

.design-subheader {
  .flexAlignCenter();
  justify-content: space-between;
  height: 72px;
}

@primary-color: #6E3BD1;@page-header-heading-title: 24px;@btn-border-radius-base: 4px;@btn-border-radius-sm: 4px;