@import 'styles/variable.less';

.design-step {
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-bottom: 24px;
  &:last-child {
    margin-bottom: 0px;
  }
  > span:first-child {
    color: @secondary;
    .fs-16();
    .fw-600();
    line-height: 20px;
  }
  > span:last-child {
    color: @grayshade;
    .fs-12();
    .fw-500();
  }
}

/* Design Tool Header */
.design-tool-header {
  background-color: @avatarTitle;
  position: absolute;
  top: 0;
  z-index: 600;
  height: 40px;
  width: 100%;
  .flexAlignCenter();
  justify-content: space-between;
  padding-right: 16px;
  .layer-dropdown-main {
    .layer-dropdown {
      background-color: @secondary;
      border: 0;
      height: 40px;
      border-radius: 0px;
      .fs-14();
      .fw-600();
      line-height: 20px;
      .flexAlignCenter();
      gap: 12px;
      > span {
        color: @white;
      }
    }
    .ant-dropdown-open {
      > svg {
        rotate: 180deg;
      }
    }
  }
  .map-tool-element {
    .flexAlignCenter();
    gap: 24px;
    > div {
      .flexAlignCenter();
      > span {
        color: @white;
        .fs-14();
        .fw-600();
        margin-right: 16px;
      }
      .mark-tool,
      .map-element {
        .flexAlignCenter();
        gap: 8px;
        > div {
          cursor: pointer;
          .flexAlignCenter();
          border-radius: 4px;
          &:hover {
            background-color: @secondary;
          }
        }
        .active-element {
          background-color: #1ee0cd;
          &:hover {
            background-color: #1ee0cd;
          }
        }
      }
    }
  }
}
.design-collapse-main {
  position: absolute;
  top: 0px;
  left: 8px;
  background: @white;
  width: 300px;
  max-height: 700px;
  border-radius: 4px;
  overflow: auto;
  box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.25);
  .design-collapse {
    margin-top: 0 !important;
    border: 0;
    border-radius: 0;
    > div {
      > div:first-child {
        padding: 8px;
        border-radius: 0;
      }
      .ant-collapse-header-text {
        // padding-left: 8px;
        color: @grayshade;
        .fs-14();
        .fw-500();
      }
    }
    .ant-collapse-content-box {
      padding: 8px !important;
    }
    .ant-collapse-item-active {
      .ant-collapse-header-text {
        color: #696b72;
        .fs-14();
        .fw-600();
      }
    }
    .ant-collapse-content {
      border-top: 0 !important;
    }
    .ant-collapse-item.ant-collapse-item-active {
      .ant-collapse-expand-icon {
        padding: 0;
        rotate: 90deg;
        svg {
          fill: @black !important;
        }
      }
    }
  }
  .ad-data-list {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    .ad-data-label {
      width: 100%;
      display: flex;
      justify-content: space-between;
      > div {
        display: flex;
        > p {
          margin: 0;
          color: @grayshade;
          .fs-12();
          .fw-500();
          padding-right: 2px;
        }
      }
      > div:last-child {
        > svg {
          cursor: pointer;
        }
      }
    }
  }
  .no-data {
    color: @grayshade;
    .fs-12();
    .fw-500();
    align-items: center;
  }
}
.alt-range-nfo {
  .flexJustifyAlignCenter();
  justify-content: flex-start;
  gap: 5px;
  .fs-12();
  color: @red;
}

@primary-color: #6E3BD1;@page-header-heading-title: 24px;@btn-border-radius-base: 4px;@btn-border-radius-sm: 4px;