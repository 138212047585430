.main-layout {
  width: 100vw;
  height: 100vh;
  background-color: var(--white);
  position: relative;
}

.main-header {
  position: relative;
  z-index: 1010;
  height: 56px;
  background-color: var(--white);
  padding-inline: 40px;
  padding-inline-end: 18px;
  box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.15);
}

.main-header-v2 {
  padding-inline: 40px;
  padding-inline-end: 18px;
}

.main-content {
  height: calc(100vh - 56px);
  overflow: auto;
  &::-webkit-scrollbar {
    width: 5px;
    background-color: var(--white);
  }
  &::-webkit-scrollbar-thumb {
    background: var(--secondary);
    border-radius: 3px;
  }
}
.data-sidebar {
  background-color: var(--white) !important;
  box-shadow: -2px 0px 10px 0px rgba(0, 0, 0, 0.15);
}
.pt-32 {
  padding-top: 32px;
}
.pt-24 {
  padding-top: 24px;
}
.leaflet-control-attribution {
  display: none;
}
.map-container {
  height: 100%;
  width: 100%;
}
.leaflet-control {
  top: 150px;
}
.leaflet-div-icon {
  background-color: #1ee0cd;
  border: 0;
  border-radius: 50%;
  height: 10px !important;
  width: 10px !important;
  margin-left: -5px !important;
  margin-top: -5px !important;
}

.child-content {
  position: relative;
  height: calc(100vh - 56px);
  width: 100%;
}

.full-screen-loader,
.content-screen-loader {
  position: absolute;
  /* background-color: transparent; */
  background-color: #ffffff24;
  height: 100%;
  width: 100%;
  z-index: 9999;
  display: flex;
  justify-content: center;
}

.full-screen-loader div:first-child,
.content-screen-loader div:first-child {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.full-screen-loader .loading-text,
.content-screen-loader .loading-text {
  color: var(--primary);
  font-size: 18px;
  line-height: 24px;
  font-weight: 400;
}

.spinner {
  animation: spin infinite 1s linear;
  height: 50px !important;
  width: 50px !important;
}

.light-triangle-icon {
  margin-top: -22px !important;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
