@import 'styles/variable.less';

.assigned-design {
  padding: 20px 96px 24px 0;
  .input-clr {
    padding: 4px 16px;
    border: 0;
    height: 32px;
    color: #696B72 !important;
    border-radius: 4px;
    background: #F1F1F1 !important;
    .fw-500();
    cursor: default;
    > span {
      margin-right: 8px;
    }
    > input {
      color: #696B72 !important;
      background: #F1F1F1 !important;
    }
  }
  .input-tlrnc{
    direction: rtl;
    .ant-input-prefix{
      margin: 0;
    }
    >input{
      cursor: default;
      text-align: end;
    }
  }
  .user-avatar {
    background-color: @white;
    color: #696B72;
    border: 1px solid @secondary;
    vertical-align: middle;
    font-size: 14px !important;
  }
  .assign-btn {
    margin: 0 !important;
    width: 159px !important;
    box-shadow:none !important;
    // box-shadow: 0px 2px 10px 0px rgba(110, 59, 209, 0.15);
  }
}
@primary-color: #6E3BD1;@page-header-heading-title: 24px;@btn-border-radius-base: 4px;@btn-border-radius-sm: 4px;