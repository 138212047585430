@import 'styles/variable.less';

.preview-report-drawer {
  .ant-drawer-header {
    height: 58px;
    background-color: @headerBackClr;
    .ant-drawer-header-title {
      width: 130px;
      flex: none;
      .flexAlignCenter();
      .ant-drawer-close {
        margin: 0;
      }
      .ant-drawer-title {
        .fs-14();
        .fw-500();
        line-height: 19.12px;
        margin-bottom: 2px;
        color: @primary;
      }
    }
    .ant-drawer-extra {
      button {
        height: 28px;
        min-width: 132px;
        padding: 0 8px;
        .fs-14();
        .fw-500();
        line-height: 19.12px;
      }
    }
    .ant-drawer-extra {
      width: calc(100% - 132px);
      > div {
        width: 100%;
        justify-content: space-evenly;
        > div {
          width: 50%;
          .flexAlignCenter();
          justify-content: flex-end;
          .header-title {
            .fs-16();
            .fw-600();
            line-height: 21.86px;
            color: @primary;
          }
        }
      }
    }
  }
  .ant-drawer-body {
    background-color: @white;
    padding: 16px;
    overflow: auto;
    width: 100vw;
    scroll-behavior: smooth;
    .scrollbarW5();
    .flexJustifyCenter();
    .report-content-main {
      height: 100%;
      .flexJustifyCenter();
      background-color: @white;
      padding: 16px;
      .scrollbarW5();
      overflow: auto;
    }
    .preview-report--main {
      width: 1122px;
      padding-top: 0;
      padding-bottom: 0;
      > div {
        .scrollbarW5();
        .report-code-title {
          height: 52px;
          text-align: center;
          vertical-align: middle;
          background-color: #f7f3ff;
          line-height: 52px;
          border-radius: 8px;
          .fw-700();
          .fs-18();
          color: @primary;
        }
        .report-code-content {
          .border-2();
          margin-top: 16px;
          margin-bottom: 16px;
          border-radius: 8px;
          .report-content-title {
            padding: 12px;
            .borderBottom();
            > span {
              .fs-16();
              .fw-700();
              line-height: 20px;
              color: @primary;
            }
          }
          .sec11-title-header {
            padding-top: 0;
            margin-bottom: 12px;
          }
          .sec8-title-header{
            padding-top: 12px;
          }
          .data-sources-tool {
            padding: 12px;
          }
          .reference-content {
            min-height: 228px;
            width: 100%;
            > div {
              &:first-child,
              &:last-child {
                width: 49%;
                > ul {
                  padding-left: 48px;
                  list-style: none;
                  > li {
                    .fs-14();
                    .fw-500();
                    line-height: 26px;
                  }
                }
              }
              &:nth-child(2) {
                min-height: 228px;
              }
              float: left;
            }
          }
          .obs-terrain-content {
            .table-note {
              .fs-14();
              .fw-500();
              line-height: 20px;
              color: @grayshade;
            }
          }
          //step-2
          .report-content-data {
            padding: 12px;
            padding-top: 0;
            .procedure-detail-content {
              padding-top: 12px;
              .procedure-type-list {
                background-color: #fbfbfb;
                padding: 4px 12px;
                width: 100%;
                margin-bottom: 16px;
                > span {
                  .fs-12();
                  .fw-400();
                  padding-bottom: 6px;
                  line-height: 16.39px;
                  color: @secondary;
                }
                .type-list {
                  padding-top: 6px;
                  > div {
                    float: left;
                    &:first-child {
                      padding-right: 70px;
                    }
                    .list-title {
                      padding-left: 5px;
                      text-transform: uppercase;
                      .font1460019();
                      color: @typocolor;
                    }
                  }
                }
              }
            }
            .type-data-list {
              .list-data-card {
                float: left;
                margin: 0 16px 16px 0;
              }
            }
            .remark-list {
              .list-data-card {
                margin: 0 !important;
              }
            }
            .general-data-content {
              > div {
                &:first-child {
                  padding-top: 12px;
                }
                &:nth-child(2) {
                  padding-top: 16px;
                }
              }
              // .type-data-list {
              //   .list-data-card {
              //     float: left;
              //     margin: 6px;
              //   }
              // }
            }
            .ifp-design-content {
              margin-top: 12px;
              border-radius: 4px;
            }
            .design-html {
              padding-top: 12px;
            }
            .plan-profile-content {
              .report-data {
                padding: 16px 0;
              }
            }
            .sid-route-content {
              margin-top: 12px;
              border: 0.5px solid @borderclr;
              border-radius: 4px;
              .sid-route-table {
                th.table-col-data {
                  background-color: @headerBackClr !important;
                  padding: 8px 15px;
                  .fs-14();
                  .fw-500();
                  color: @secondary;
                  line-height: 20px;
                  &:first-child::before {
                    display: none;
                  }
                }
                .sid-title {
                  .font1460019();
                  color: @secondary;
                }
                .table-sid-row-data {
                  .table-col-data {
                    padding: 8px 15px;
                    border-bottom: 0.5px dashed @borderclr;
                    &:first-child {
                      border-right: 0.5px dashed @borderclr;
                    }
                    &:last-child {
                      text-align: start !important;
                    }
                  }
                  &:last-child {
                    .table-col-data {
                      border-bottom: 0;
                    }
                  }
                }
              }
            }
            .coding-table-content {
              margin-top: 16px;
            }
          }
        }

        .sec-11,.sec-8 {
          .report-content-data {
            padding: 0;
            .report-data {
              margin-left: 12px;
            }
            .obstacle-table {
              margin: 12px;
              margin-bottom: 16px;
            }
          }
        }
      }
    }
  }
}

.clearfix {
  clear: both;
  display: table;
}

.k-pdf-export .report-code-title {
  display: none !important;
}
.k-pdf-export .pdf-export-container {
  margin-top: 16px;
}
.k-pdf-export .obstacle-map {
  height: 650px;
}

// .k-pdf-export .report-code-content {
//   page-break-inside: avoid !important;
// }

// page template
.aero-pdf-header {
  position: absolute;
  top: 0;
  left: 20px;
  width: calc(100% - 40px);
  height: 40px;
  background-color: @white;
  border-bottom: 0.5px solid @borderclr;
  > div {
    width: 100%;
    height: 100%;
    position: relative;
    top: 10px;
    .aero-header-label,
    .aero-header-title {
      height: 100%;
      color: @primary;
      vertical-align: middle;
      display: inline-block;
      font-size: 18px;
      font-weight: 500;
    }
    .aero-header-label {
      min-width: 40%;
    }
    .aero-header-title {
      min-width: 60%;
    }
  }
}
.aero-pdf-footer {
  position: absolute;
  bottom: 0;
  left: 20px;
  width: calc(100% - 40px);
  height: 40px;
  background-color: @white;
  border-top: 0.5px solid @borderclr;
  > div {
    min-width: 100%;
    position: relative;
    bottom: -10px;
    color: @primary;
    text-align: end;
    vertical-align: middle;
    font-size: 16px;
    font-weight: 500;
  }
}

@primary-color: #6E3BD1;@page-header-heading-title: 24px;@btn-border-radius-base: 4px;@btn-border-radius-sm: 4px;