:root {
  --white: #ffffff;
  --black: #000;
  --primary: #6e3bd1;
  --secondary: #42444a;
  --typocolor: #696b72;
  --MNRPLR: 'Manrope';
}

@font-face {
  font-family: 'Manrope';
  src: local('ManropeRegular'), url('./fonts/ttf/ManropeRegular.ttf') format('truetype');
}

body {
  margin: 0;
  font-family: var(--MNRPLR) !important;
  scroll-behavior: smooth;
}

code {
  font-family: var(--MNRPLR) !important;
}
