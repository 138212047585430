@primary: #6e3bd1;
@secondary: #42444a;
@white: #fff;
@white1: #f2f2f2;
@headerBackClr: #f7f3ff;
@black: #000;
@black1: #030303;
@titleColor: #333333;
@darkGray: #f3f3f3;
@grayshade: #95969d;
@lightdarkGray: #f6f6f6;
@veryLightGray: #d9d9d9;
@verylightBlack: #ababab;
@green: #27ae60;
@lightBlack: #4a4a4a;
@avatarTitle: #727272;
@back: #f5f5f5;
@red: #eb5757;
@red1: #da0000;
@roottableth: #3c3c3c;
@analyticBack: #fbfaff;
@ghostbtnBack: #e0e0e0;
@ghostbtnBack1: #e6e6e6;
@ghostbtnBack2: #ececec;
@notification: #bdbdbd;
@subheader: #8f8f8f;
@btnTitle: #a4a4a4;
@btnLabel: #747474;
@placeholder: #bfbfbf;
@formLabel: #4f4f4f;
@borderclr: #c7c7cc;
@orange: #f2994a;
@tooltiptitle: #828282;
@lightGreen: #43ffdd;
@blue: #2f80ed;
@typocolor: #696b72;
@ttlHeight: calc(100vh - 56px);
.border-1 {
  border: 1px solid @primary;
}
.border-2 {
  border: 0.5px solid @borderclr;
}
.borderBottom {
  border-bottom: 0.5px solid @borderclr;
}
.borderTop {
  border-top: 0.5px solid @borderclr;
}
.fslh-12 {
  font-size: 12px;
  line-height: 14px;
}
.fs-10 {
  font-size: 10px !important;
}
.fs-12 {
  font-size: 12px !important;
}
.fs-14 {
  font-size: 14px;
}
.fslh-14 {
  .fs-14();
  line-height: 17px;
}
.fs-16 {
  font-size: 16px;
}
.fs-18 {
  font-size: 18px;
}
.fs-20 {
  font-size: 20px;
}
.fs-22 {
  font-size: 22px;
}
.fs-24 {
  font-size: 24px;
}
.fs-28 {
  font-size: 28px;
}
.fw-400 {
  font-weight: 400;
}
.fw-500 {
  font-weight: 500;
}
.fw-600 {
  font-weight: 600;
}
.fw-700 {
  font-weight: 700;
}
.ff {
  font-family: 'Manrope' !important;
}
.font1460019 {
  .fs-14();
  .fw-600();
  line-height: 19.12px;
}
.flexAlignCenter {
  display: flex;
  align-items: center;
}
.flexJustifyCenter {
  display: flex;
  justify-content: center;
}
.flexJustifyBetween {
  display: flex;
  justify-content: space-between;
}
.flexDirectionColumn {
  display: flex;
  flex-direction: column;
}
.flexJustifyAlignCenter {
  display: flex;
  justify-content: center;
  align-items: center;
}
.scrollbarW5 {
  &::-webkit-scrollbar {
    width: 5px;
    height: 5px;
    background-color: @white;
  }
  &::-webkit-scrollbar-thumb {
    background: @secondary;
    border-radius: 3px;
  }
}
.scrollbarW3 {
  &::-webkit-scrollbar {
    width: 3px;
    background-color: @white;
  }
  &::-webkit-scrollbar-thumb {
    background: @secondary;
    border-radius: 3px;
  }
}
.noscrollbar {
  &::-webkit-scrollbar {
    width: 0px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: @typocolor;
  }
}
