@import 'styles/variable.less';

.main-detail {
  padding: 24px 96px;
  .detail-label {
    padding-bottom: 24px;
    :first-child {
      .fs-22();
      .fw-600();
    }
  }
  .member-detail {
    :first-child {
      font-size: 17px;
      .fw-600();
      color: @primary;
      padding-bottom: 12px;
      border-bottom: 3px solid @primary;
    }
    :nth-child(2) {
      margin-top: 12px;
      margin-bottom: 32px;
      border-color: @borderclr;
    }
  }
  .form-main {
    height: calc(100vh - 290px);
    margin-bottom: 24px;
    overflow: auto;
    &::-webkit-scrollbar {
      width: 5px;
      background-color: @white;
    }
    &::-webkit-scrollbar-thumb {
      background: @subheader;
      border-radius: 3px;
    }
  }
  .input-main {
    overflow: auto;
    margin-right: 0 !important;
  }
  .detail-form {
    .input-label {
      > div:first-child {
        > div:first-child {
          > label {
            &::before{
              display: none;
            }
            .fw-500();
            .fs-14();
            line-height: 19px;
            color: @secondary;
          }
        }
        .form-input {
          width: 299px;
          border-radius: 4px;
          color: #696b72;
          @media (max-width: 1255px) {
            width: 240px;
          }
          @media (max-width: 1080px) {
            width: 220px;
          }
          @media (max-width: 1024px) {
            width: 200px;
          }
        }
        .form-input-select {
          > div {
            border-radius: 4px;
          }
        }
      }
    }
    .phone-input-num{
      .ant-input-number-handler-wrap{
        display: none;
      }
    }
    .form-divider {
      margin: 8px 0 24px;
      border-color: @borderclr;
    }
    .optional-data {
      font-size: 15px;
      .fw-600();
    }
    > div:last-child {
      display: flex;
      justify-content: flex-end;
      .save-btn {
        width: 185px;
        box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.15);
        border-radius: 4px;
        height: 32px;
        .flexJustifyAlignCenter();
        > span {
          .fs-14();
          .fw-600();
        }
      }
    }
  }
}

@primary-color: #6E3BD1;@page-header-heading-title: 24px;@btn-border-radius-base: 4px;@btn-border-radius-sm: 4px;