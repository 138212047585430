@import 'styles/variable.less';

/* Collect Sidebar --------- Start ----------- */
.inr-main-sidebar {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: inherit;
  .data-stock {
    background-color: #f7f3ff;
    text-align: center;
    color: @primary;
    .fs-16();
    .fw-600();
    height: 40px;
    line-height: 40px;
  }
  .data-collapse-div {
    height: calc(100% - 104px);
    background-color: @white;
    overflow: auto;
    .noscrollbar();
    .collapse-main-div {
      margin-top: 0 !important;
      border: 0;
      border-radius: 0;
      background: @white;
      .ant-collapse-header-text {
        padding-left: 8px;
        color: @grayshade;
        .fs-14();
        .fw-500();
      }
      .ant-collapse-item-active {
        .ant-collapse-header-text {
          color: #696b72;
          .fs-14();
          .fw-600();
        }
      }
      .ant-collapse-item {
        border-bottom: 0.5px solid @borderclr;
        border-radius: 0px;
        > div:first-child {
          padding: 11px 8px;
          border-radius: 0;
        }
        .ant-collapse-expand-icon {
          rotate: 270deg;
          padding: 0;
        }
      }
      .ant-collapse-content {
        border-top: 0 !important;
      }
      .ant-collapse-item.ant-collapse-item-active {
        .ant-collapse-expand-icon {
          rotate: 360deg;
          svg {
            fill: @black !important;
          }
        }
      }
    }
    .ant-collapse-content-box {
      padding: 0 12px 12px 8px !important;
    }
    .ad-data-list {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 8px;
      > div {
        padding-left: 8px;
        > p,
        > span {
          margin: 0;
          color: @grayshade;
          .fs-12();
          .fw-500();
          padding-right: 2px;
        }
      }
      .ad-data-content {
        display: flex;
        justify-content: space-between;
        width: 100%;
        > div {
          > p {
            margin: 0;
            color: @grayshade;
            .fs-12();
            .fw-500();
            padding-right: 2px;
          }
        }
      }
    }
    .temp-data {
      gap: 5px;
      padding-left: 8px;
    }
    .no-data {
      color: @grayshade;
      .fs-12();
      .fw-500();
      align-items: center;
    }
  }
  .start-design {
    height: 64px;
    border-top: 0.5px solid @grayshade;
    background-color: @white;
    text-align: center;
    .flexJustifyAlignCenter();
    > div {
      cursor: pointer;
      .flexAlignCenter();
    }
    .start-design-btn {
      display: flex;
      justify-content: center;
      // flex-direction: row-reverse;
      align-items: center;
      gap: 5px;
      > span {
        color: @primary;
        .fs-14();
        .fw-600();
      }
      // >span:first-child{
      //   order: 2;
      //   display: block;
      // }
      // >span:last-child{
      //   order: 1;
      // }
    }
  }
}
.layer-legend {
  position: absolute;
  top: 8px;
  right: 24px;
  z-index: 1001;
}
.small-layer-legend {
  position: absolute;
  height: auto !important;
  width: auto !important;
  top: 8px;
  right: 8px;
  z-index: 1000;
}
.design-layer-data {
  top: 48px;
}
/* Collect Sidebar --------- END ----------- */

/* Design Sidebar */
.design-main-sidebar {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: @white;
  .design-sid {
    height: 40px;
    line-height: 40px;
    text-align: left;
    background-color: #f7f3ff;
    color: @primary;
    .fs-16();
    .fw-600();
    padding-left: 16px;
  }
  .data-design {
    height: calc(100% - 104px);
    background-color: @white;
    padding: 0px;
    overflow: auto;
    box-shadow: none !important;
    // box-shadow: -2px 0px 10px 0px rgba(0, 0, 0, 0.15);
    .noscrollbar();
    .design-process-tab {
      .ant-tabs-nav {
        margin: 0;
      }
      .ant-tabs-nav-wrap {
        width: 100%;
        .ant-tabs-nav-list {
          width: 100%;
        }
      }
      .ant-tabs-tab {
        width: 50%;
        margin: 0;
        justify-content: center;
        .ant-tabs-tab-btn {
          color: #696b72;
          text-align: center;
          .fs-14();
          .fw-500();
          line-height: 20px;
          text-shadow: none;
        }
      }
      .ant-tabs-tab-active {
        background: @white;
        box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.15);
        .ant-tabs-tab-btn {
          font-weight: 600;
        }
      }
      .ant-tabs-nav-list::after {
        display: none;
      }
      .ant-tabs-ink-bar {
        left: 50% !important;
        width: 50% !important;
      }
    }
    .design-active {
      .ant-tabs-ink-bar {
        left: 0px !important;
      }
    }
    .design-tab-cntent {
      padding: 12px 0;
      width: 100%;
      height: calc(100% - 45px);
      .inprogress-sid-list {
        .flexDirectionColumn();
        justify-content: space-between;
        .procedure-data-list {
          padding: 0 8px;
          height: calc(100% - 60px);
          overflow: auto;
          .scrollbarW3();
        }
        .proc-design-btn {
          .flexJustifyAlignCenter();
          margin-bottom: 12px;
          padding-top: 12px;
          border-top: 0.5px solid @borderclr;
          > button {
            width: 159px;
          }
        }
      }
      .inprogress-sid-list,
      .completed-sid-list {
        height: 50%;
        .ant-radio-group {
          width: 100%;
          max-height: calc(100% - 48px);
          overflow: auto;
          &::-webkit-scrollbar {
            width: 0px;
          }
          > div {
            margin: 5px 0;
          }
        }
      }
      .inprogress-sid-list,
      .completed-sid-list {
        width: 100%;
        .sid-main-list {
          width: 100%;
          .deactive-sid {
            width: 100%;
            border-radius: 4px;
            padding: 12px;
            border: 0.5px solid #c7c7cc;
            background: @white;
            margin-inline-end: 0px;
            > span:last-child {
              width: calc(100% - 16px);
              padding-inline-start: 12px;
              display: inline-flex;
              align-items: center;
              justify-content: space-between;
              padding-inline-end: 0px;
              > span {
                color: #95969d;
                font-size: 12px;
                line-height: 16px;
              }
              > span:first-child {
                font-weight: 600;
              }
              > span:last-child {
                font-weight: 500;
              }
            }
          }
          .active-sid {
            border: 0.5px solid @primary;
            background: #f2ecfc;
            > span:last-child {
              > span {
                color: @primary;
              }
            }
          }
        }
      }
      .completed-sid-list {
        border-top: 0.5px dashed @borderclr;
        max-height: 100%;
        padding-top: 8px;
        &:last-child {
          text-align: center;
        }
        .complete-sid-radio-grp {
          padding: 0 8px;
          height: calc(100% - 72px) !important;
          overflow: auto;
          &::-webkit-scrollbar {
            width: 0px;
          }
        }
        .goto-report-btn-div {
          border-top: 0.1px solid @borderclr;
          padding-top: 12px;
        }
        .goto-aero-report-btn {
          height: 32px;
          width: 159px;
          &:disabled {
            background-color: @borderclr;
            .fs-14();
            .fw-600();
            line-height: 20px;
            color: @typocolor;
          }
        }
        .complete-sid-title {
          padding: 0 8px 4px;
          display: block;
          text-align: left;
          .fs-14();
          .fw-600();
          line-height: 20px;
          color: @green;
        }
        .nocomplete-sid-title {
          color: @secondary;
          text-align: center;
          padding-top: 16px;
        }
      }
      .add-sid-div {
        .flexJustifyAlignCenter();
        margin-top: 12px;
        .add-new-sid-btn {
          display: inline-flex;
          justify-content: center;
          align-items: center;
          color: @primary;
          font-size: 14px;
          font-weight: 600;
          line-height: 20px;
        }
      }
    }
    .process-tab-cntent {
      padding: 16px 16px;
    }
    .design-desc,
    .design-desc2 {
      color: @grayshade;
      .fs-12();
      .fw-500();
    }
    .design-desc2 {
      margin-top: 8px;
    }
    .design-steps {
      padding: 24px 0;
      padding-bottom: 0px;
    }
  }
  .procedure-data-design {
    height: calc(100% - 40px);
  }
  .start-design-div {
    height: 64px;
    background-color: @white;
    border-top: 0.5px solid @grayshade;
    .flexJustifyAlignCenter();
    > button {
      width: 159px;
    }
  }
  .started-design {
    justify-content: space-between;
    padding: 0 16px;
    .back-design-btn {
      .flexJustifyAlignCenter();
      justify-content: flex-start;
      line-height: 20px;
      color: @primary;
      padding-left: 0;
      &:hover {
        color: #6e3bd1b0;
      }
    }
    > button:last-child {
      .flexJustifyAlignCenter();
      line-height: 20px;
      width: 120px;
      > span:first-child {
        margin-inline-end: 8px;
      }
    }
  }
  /* Design Started Sidebar */

  .data-started-design {
    height: calc(100% - 122px);
    padding-top: 8px;
    .started-design-step {
      padding: 0 40px;
      .current-design-step {
        .ant-steps-item-icon {
          background-color: @primary;
          > span {
            color: @white;
          }
        }
      }
      .finished-step {
        > div {
          .ant-steps-item-icon {
            border-color: @primary;
            background-color: @white;
            > span {
              color: @primary;
            }
          }
          .ant-steps-item-content {
            .ant-steps-item-title {
              padding-inline-end: 0px;
              &::after {
                height: 2px;
                background-color: @primary;
              }
            }
          }
        }
      }
      .ant-steps-item {
        padding-inline-start: 0px !important;
        .ant-steps-item-icon {
          width: 31px;
          height: 31px;
          line-height: 31px;
          margin-inline-end: 0px;
          margin: 0;
        }
        .ant-steps-item-title {
          padding-inline-end: 0px;
          &::after {
            height: 2px;
            background-color: #f0f0f0;
          }
        }
      }
    }
    /* Step 1 */
    .data-design-content {
      height: calc(100% - 55px);
      overflow: auto;
      margin-top: 24px;
      padding: 16px;
      padding-top: 0px;
      &::-webkit-scrollbar {
        width: 0;
        background-color: var(--white);
      }
      &::-webkit-scrollbar-thumb {
        background: var(--secondary);
        border-radius: 3px;
      }
      .departure-content {
        display: flex;
        flex-direction: column;
        gap: 16px;
        > div {
          .flexAlignCenter();
          justify-content: space-between;
          > div:first-child {
            color: @secondary;
            .fs-14();
            .fw-500();
            line-height: normal;
          }
          .dep-btn-grp {
            > button {
              height: 32px;
              width: 108px;
              > span {
                .fs-14();
                .fw-500();
                line-height: normal;
                color: @grayshade;
              }
              &:last-child {
                margin-inline-start: -3px;
              }
            }
            .dep-active-btn,
            .omni-active-btn {
              border: 1px solid @primary;
              background: @white;
              > span {
                color: @primary;
              }
            }
            .dep-active-btn {
              border-start-end-radius: 4px;
              border-end-end-radius: 4px;
              border: 1px solid @primary;
              background: @white;
              box-shadow: 2px 0px 6px 0px rgba(0, 0, 0, 0.15);
              z-index: 2;
            }
            .omni-active-btn {
              border-start-start-radius: 4px;
              border-end-start-radius: 4px;
              box-shadow: -2px 0px 6px 0px rgba(0, 0, 0, 0.15);
              z-index: 2;
            }
          }
        }
        .designator-content {
          > div:last-child {
            border-radius: 4px;
            height: 32px;
            width: 214px;
            line-height: 32px;
            background-color: #f2ecfc;
            padding-left: 16px;
            color: @primary;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            .fs-14();
            .fw-500();
            > div {
              border: 1px solid @primary;
            }
            .ant-select-selection-item {
              color: @primary;
            }
          }
          .msa-content {
            background-color: unset !important;
            padding-left: 0 !important;
            .flexAlignCenter();
            justify-content: space-between;
            .ant-input-number-input {
              color: @primary;
              .fs-14();
              .fw-500();
            }
            .ant-input-number-handler-wrap {
              display: none;
            }
          }
          .select-content.ant-select-status-error {
            > div:first-child {
              border-color: @red !important;
            }
            .ant-select-arrow > svg {
              path {
                fill: @red !important;
              }
            }
          }
        }
      }
    }
  }
}
.data-started-sid {
  height: 58px;
  line-height: 58px;
  background-color: #f7f3ff;
  .flexJustifyCenter();
  flex-direction: column;
  gap: 4px;
  > span:first-child {
    color: @primary;
    .fs-16();
    .fw-600();
    line-height: normal;
  }
  > span:last-child {
    color: #696b72;
    .fs-12();
    .fw-500();
    line-height: normal;
  }
  padding-left: 16px;
}
/* Step 2 */
.data-leg-started-design {
  padding-left: 0px !important;
  padding-right: 0px !important;

  // .rsid-main-content {
  //   margin-top: 24px;
  //   margin-bottom: 16px;
  //   padding-left: 16px;
  //   padding-right: 8px;
  //   .rsdi-select-main {
  //     .flexAlignCenter();
  //     justify-content: space-between;
  //     > div:first-child {
  //       color: #696b72;
  //       .fs-14();
  //       .fw-600();
  //       line-height: normal;
  //     }
  //     > div:last-child {
  //       .flexAlignCenter();
  //       justify-content: flex-end;
  //       gap: 8px;
  //       > div:first-child {
  //         color: #696b72;
  //         .fs-14();
  //         .fw-600();
  //         line-height: normal;
  //       }
  //       .ant-select-selection-item {
  //         color: @primary;
  //       }
  //     }
  //   }
  // }
  /* Leg tabs css */
  .leg-tabs {
    margin-top: 20px;
    margin-left: 8px;
    margin-right: 8px;
    margin-bottom: 10px;
    > div:first-child {
      .ant-tabs-nav {
        border-bottom: 1px solid @primary;
        margin-bottom: 0;
        .ant-tabs-nav-wrap {
          .ant-tabs-nav-list {
            .ant-tabs-tab {
              margin-left: 0;
              border-color: @primary;
              border-bottom: 0;
              padding: 6px 8px !important;
              min-width: 50px;
              min-height: 36px;
              > div {
                color: @primary;
                .fs-14();
                .fw-600();
                line-height: 14px;
              }
              .ant-tabs-tab-remove {
                .flexAlignCenter();
                margin-left: 4px;
                margin-right: -8px;
              }
            }
            .ant-tabs-tab-active {
              background-color: @primary;
              > div {
                color: @white;
                text-transform: uppercase;
              }
              .ant-tabs-tab-remove {
                .flexAlignCenter();
                margin-left: 4px;
                margin-right: -8px;
                > svg path {
                  fill: @white !important;
                }
              }
            }
            .ant-tabs-nav-add {
              margin-left: 0px;
              border-color: @primary;
              padding: 5.5px 8px;
              min-width: 35px;
              > span {
                > svg {
                  fill: @primary;
                }
              }
            }
          }
        }
        .ant-tabs-nav-operations {
          > button {
            border: 0.1px solid @primary;
            border-radius: 4px 4px 0 0;
            min-width: 35.5px;
          }
          > button:first-child {
            padding: 8px 6px;
          }
          > button:last-child {
            margin-left: 0;
          }
        }
        &::before {
          border: 0;
        }
      }
    }
  }
  /* Leg tab content css */
  .leg-main-content {
    height: calc(100% - 116px);
    overflow: auto;
    &::-webkit-scrollbar {
      width: 0px;
    }
    .turn-type-content {
      display: flex;
      flex-direction: column;
      gap: 16px;
      padding: 12px 8px;
      padding-bottom: 16px;
      > div {
        .flexAlignCenter();
        justify-content: space-between;
        > div:first-child {
          color: #696b72;
          .fs-14();
          .fw-600();
          line-height: normal;
        }
        .path-terminator-select {
          .ant-select-selector {
            border: 0.5px solid @primary;
          }
        }
        .ant-select-selection-item {
          color: @primary;
          font-size: 14px;
          font-weight: 500;
        }
        .ant-input-number-handler-wrap {
          display: none;
        }
        .ant-input-number-input,
        .ant-input-number-suffix {
          font-size: 14px;
          font-weight: 400;
          color: @primary;
        }
      }
    }
    .point-details {
      .start-point-main {
        margin-left: 8px;
        margin-right: 8px;
        border-radius: 4px;
        background-color: #f8f8f8;
        .start-point-content {
          padding: 0 8px;
        }
        > div:first-child {
          .flexJustifyCenter();
          > div {
            .flexAlignCenter();
            margin: 8px 0;
            cursor: pointer;
            > span {
              color: @primary;
              .fs-14();
              .fw-600();
              padding-right: 5px;
            }
            .expanded-content {
              display: flex;
            }
            .collapsed-content {
              > svg {
                rotate: 180deg;
              }
            }
          }
        }
      }
      .start-point-content {
        // .info-title {
        //   color: #696b72;
        //   .fs-14();
        //   .fw-600();
        //   line-height: normal;
        //   margin-bottom: 8px;
        // }
        .general-divider {
          height: 100%;
        }
        .general-add-content {
          .flexAlignCenter();
          justify-content: space-between;
          > div {
            color: #696b72;
            .fs-12();
            .fw-600();
            line-height: normal;
          }
          .ant-input-number-input {
            color: @primary;
            .fs-12();
            .fw-500();
          }
          .ant-input-number-handler-wrap {
            display: none;
          }
        }
        .general-content {
          > div {
            .flexAlignCenter();
            justify-content: space-between;
            margin-bottom: 12px;
            > div {
              .fs-12();
              .fw-600();
              line-height: normal;
              &:first-child {
                color: #696b72;
              }
              &:last-child {
                color: @primary;
              }
            }
            &:last-child {
              margin-bottom: 0;
            }
          }

          .max-der-text {
            gap: 5px;
            justify-content: flex-start !important;
            > span:last-child {
              color: @red;
              .fs-10();
              .fw-400();
              font-style: italic;
              line-height: normal;
            }
          }
        }
        .der-content {
          .flexAlignCenter();
          justify-content: flex-start;
          gap: 8px;
          > span:first-child {
            color: #696b72;
            .fs-14();
            .fw-600();
            line-height: normal;
          }
          > span:last-child {
            .flexAlignCenter();
            justify-content: flex-end;
            > span {
              color: @red;
              .fs-10();
              .fw-400();
              font-style: italic;
              line-height: normal;
            }
          }
        }
      }
      .tab-extra-content {
        padding: 0 8px;
        .tab-group {
          .flexJustifyAlignCenter();
          .point-btn-grp {
            // margin-top: 12px;
            border-radius: 4px;
            border: 0.5px solid @borderclr;
            background-color: #fcfcfc;
            > button {
              padding: 0px 17.8px;
              border: 0;
              height: 24px;
              border-start-end-radius: 0;
              border-end-end-radius: 0;
              border-start-start-radius: 4px;
              border-end-start-radius: 4px;
              box-shadow: none;
              > span {
                color: #696b72;
                .fs-12();
                .fw-600();
                line-height: normal;
              }
            }
            .point-active-btn {
              border-start-end-radius: 4px;
              border-end-end-radius: 4px;
              border-start-start-radius: 4px;
              border-end-start-radius: 4px;
              border: 0.5px solid @primary;
              z-index: 5;
              > span {
                color: @primary;
              }
            }
            .paoa-btn {
              background-color: rgba(105, 107, 114, 0.15);
            }
            .paoa-btn-active-btn {
              border: 0.5px solid #696b72;
              background-color: #696b72;
              > span {
                color: @white;
              }
            }
          }
        }

        /* End Point Leg */
        .leg-tab {
          padding-top: 16px;
        }
        .end-alt-grad-div {
          border-radius: 4px;
          border: 0.5px solid #c7c7cc;
          flex-direction: column;
          background: @white;
          // box-shadow: -2px 0px 10px 0px rgba(0, 0, 0, 0.15);
          justify-content: unset !important;

          .alt-grad-content {
            width: 100%;
            padding: 12px;
          }
        }
        .end-alt-grad-info {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          margin-top: 8px;
          > span {
            color: @red;
            font-size: 10px;
            font-style: italic;
            font-weight: 400;
          }
        }
        .end-point {
          > div:first-child {
            .flexAlignCenter();
            justify-content: space-between;
            > span:first-child {
              color: #696b72;
              .fs-14();
              .fw-600();
              line-height: 19px;
            }
            > span:last-child {
              color: @secondary;
              .fs-12();
              .fw-600();
              line-height: normal;
            }
          }
          .way-point {
            display: flex;
            flex-direction: column;
            gap: 12px;
            .select-waypoint {
              display: flex;
              justify-content: space-between;
              align-items: center;
              > label {
                padding: 8px 0px 8px 5px;
                > span {
                  color: @grayshade;
                  .fs-12();
                  .fw-500();
                  line-height: normal;
                }
              }
              > button {
                color: @primary;
                font-size: 12px;
                font-weight: 500;
                &:hover {
                  background-color: unset;
                }
              }
            }
            .create-waypoint {
              .flexAlignCenter();
              justify-content: space-between;
              > label {
                padding: 8px 0 8px 5px;
                > span {
                  color: @grayshade;
                  .fs-12();
                  .fw-500();
                  line-height: normal;
                }
              }
              > input {
                margin-right: 8px;
                color: @borderclr;
                .fs-12();
                .fw-400();
                line-height: normal;
              }
            }
            .lat-lng-main {
              padding: 8px 8px 8px 5px;
              display: flex;
              flex-direction: column;
              gap: 10px;
              .lat-long {
                .flexAlignCenter();
                justify-content: space-between;
                > label {
                  :last-child {
                    color: @grayshade;
                    .fs-12();
                    .fw-500();
                    line-height: normal;
                  }
                }
                > div {
                  .ant-input-number-handler-wrap {
                    display: none;
                  }
                  > div:last-child {
                    > input {
                      // height: 26px;
                      color: @primary;
                      .fs-12();
                      .fw-400();
                    }
                  }
                }
                > div:last-child {
                  margin-left: 4px;
                }
              }
              .waypoint-label {
                display: flex;
                justify-content: flex-end;
                gap: 112px;
                > button {
                  // height: 28px;
                  // padding: 2px 0px;
                  width: 98px;
                  > span {
                    .fs-12();
                    .fw-500();
                  }
                }
                > input {
                  color: @borderclr;
                  .fs-12();
                  .fw-400();
                  line-height: normal;
                }
              }
            }
            .checked-waypoint {
              border-radius: 4px;
              border: 0.5px solid @primary;
              background: #f2ecfc;
              .ant-radio-wrapper-checked {
                > span:last-child {
                  color: @primary !important;
                  .fw-600() !important;
                }
              }
            }
            .alt-range-error-info {
              border-color: #696b72;
              background: #dcdcdc;
              .ant-radio-wrapper-checked {
                > span:last-child {
                  color: @secondary !important;
                  .fw-600() !important;
                }
              }
              .ant-radio-inner {
                border-color: @secondary;
                background-color: @secondary;
              }
            }
            .checked-createpoint {
              > input {
                border: 0;
              }
            }
            .checked-latlngpoint {
              .lat-lng-input {
                border: 1px solid @white;
              }
              .waypoint-label {
                > input {
                  border: 0;
                }
              }
            }
          }
          .waypoint-divider {
            margin: 12px 0;
            border-block-start-color: @borderclr;
          }
          .select-length {
            padding: 0 0 16px 0;
            .flexAlignCenter();
            justify-content: space-between;
            > span {
              color: #696b72;
              .fs-14();
              .fw-600();
              line-height: normal;
            }
            > input {
              // height: 28px;
              width: 184px;
              color: @primary;
              .fs-12();
              .fw-400();
              background-color: #f2ecfc;
              border: 0;
              line-height: normal;
            }
          }
          .fly-mode-content {
            .flexAlignCenter();
            justify-content: space-between;
            > div:first-child {
              color: #696b72;
              .fs-14();
              .fw-600();
              line-height: normal;
            }
            .fly-btn-grp {
              .fly-btn {
                .flexJustifyAlignCenter();
                height: 28px;
                // padding: 4px 16px 2px 2px;
                width: 92px;
                border: 1px solid #696b72;
                > span:first-child {
                  margin-inline-end: 2px;
                }
                > span:last-child {
                  .fs-12();
                  .fw-500();
                  line-height: normal;
                  color: #696b72;
                }
                &:last-child {
                  margin-inline-start: -3px;
                }
              }
              .over-active-btn,
              .by-active-btn {
                border-color: @primary;
                .ant-btn-icon:not(:last-child) {
                  margin-inline-end: 2px;
                }
                > span:last-child {
                  color: @primary;
                }
              }
              .over-active-btn {
                border-start-end-radius: 4px;
                border-end-end-radius: 4px;
                box-shadow: 2px 0px 6px 0px rgba(0, 0, 0, 0.15);
                z-index: 2;
              }
              .by-active-btn {
                border-start-start-radius: 4px;
                border-end-start-radius: 4px;
                box-shadow: -2px 0px 6px 0px rgba(0, 0, 0, 0.15);
                z-index: 2;
              }
            }
          }
          .omni-height {
            color: @primary;
            .fs-12();
            .fw-600();
            line-height: normal;
          }
          .min-distance {
            padding-top: 16px;
            .flexAlignCenter();
            justify-content: space-between;
            > span:first-child {
              color: #696b72;
              .fs-14();
              .fw-600();
              line-height: normal;
            }
            > span:last-child {
              width: 184px;
              // height: 28px;
              border: 0;
              background-color: #f2ecfc;
              > input {
                color: @primary;
                background-color: #f2ecfc;
                .fs-12();
                .fw-500();
              }
              .ant-input-suffix {
                color: @primary;
                .fs-12();
                .fw-400();
              }
            }
          }
        }

        /* Alt Gradiant Leg */
        .alt-grad-tab {
          display: flex;
          flex-direction: column;
          .start-alt-info {
            padding-top: 12px;
            .flexAlignCenter();
            justify-content: flex-start;
            > span {
              color: @red;
              .fs-10();
              .fw-400();
              font-style: italic;
              line-height: normal;
            }
          }
          .alt-grad-height {
            padding: 20px 0 16px 0;
          }
          .end-alt {
            padding-top: 16px;
          }
        }
        .alt-grad-content {
          .flexAlignCenter();
          justify-content: space-between;
          > span {
            color: #696b72;
            .fs-14();
            .fw-600();
            line-height: normal;
          }
          .alt-input {
            // height: 28px;
            width: 180px;
            color: @primary;
            background-color: #f2ecfc;
            border: 0;
            .ant-input-number-input {
              background-color: #f2ecfc;
            }
          }
          .ant-input-number-input {
            color: @primary;
            .fs-12();
            .fw-500();
          }
          .ant-input-number-handler-wrap {
            display: none;
          }
          .ant-input-number-suffix {
            margin: 0;
            .fs-12();
            .fw-500();
            padding-right: 6px;
            color: @primary;
          }
        }

        /* Turn Leg  */
        .turn-leg {
          .turn-dir-content {
            .flexAlignCenter();
            justify-content: space-between;
            > span {
              color: #696b72;
              .fs-14();
              .fw-600();
              line-height: normal;
            }
            .turn-dir-select {
              > div {
                .flexAlignCenter();
                padding: 0;
                // height: 28px;
                border: 0;
                background-color: #f2ecfc;
                > span:last-child {
                  padding-left: 8px;
                  color: @primary;
                  .fs-12();
                  .fw-500();
                }
              }
            }
          }
          .turn-angle-content {
            padding: 12px 0 12px;
            > input {
              background-color: #f2ecfc;
              border: 0;
              color: @primary;
              width: 184px;
              // height: 28px;
              .fs-12();
              .fw-500();
            }
          }
          .bank-angle {
            padding: 12px 0 0px;
            > div {
              width: 184px;
              // height: 28px;
              color: @primary;
              border: 0.5px solid @primary;
            }
            .ant-input-number-input {
              color: @primary;
              .fs-12();
              // height: 28px;
              .fw-500();
            }
            .ant-input-number-handler-wrap {
              display: none;
            }
          }
          .bank-angle-info {
            padding-top: 10px;
            .flexAlignCenter();
            justify-content: flex-start;
            > span {
              color: @red;
              .fs-10();
              .fw-400();
              font-style: italic;
              line-height: normal;
            }
          }
          .outbound-track {
            padding: 12px 0 12px;
            .course-div {
              .ant-input-number-handler-wrap {
                display: none;
              }
              .ant-input-number-input,
              .ant-input-number-suffix {
                font-size: 12px;
                font-weight: 400;
                color: @primary;
                line-height: 18px;
              }
            }
          }
          .reaction-time {
            padding-top: 0;
          }
        }

        /* Data Review Leg*/
        .data-review-leg {
          .data-review-content {
            .flexAlignCenter();
            justify-content: space-between;
            .data-content-info {
              display: flex;
              gap: 8px;
              > span:first-child {
                color: #696b72;
                .fs-14();
                .fw-600();
                line-height: normal;
              }
            }
            > span:first-child {
              color: #696b72;
              .fs-14();
              .fw-600();
              line-height: normal;
            }
            > span:last-child {
              padding: 0;
              height: 30px;
              width: 184px;
              border: 0;
              > input {
                padding-left: 8px;
                background-color: #f2ecfc;
                color: @primary;
                .fs-12();
                .fw-500();
                border-radius: 4px 0 0 4px;
              }
              .ant-input-suffix {
                margin: 0;
                border-radius: 0 4px 4px 0;
                .fs-12();
                .fw-500();
                padding-right: 6px;
                color: @primary;
                background-color: #f2ecfc;
              }
            }
            .data-review-ias {
              width: 184px;
              border: 0.5px solid @primary;
              .ant-input-number-handler-wrap {
                display: none;
              }
              .ant-input-number-input {
                color: @primary;
                // height: 28px;
              }
            }
            .data-review-twc {
              width: 184px;
              margin-top: 12px;
              border: 0.5px solid @primary;
              .ant-input-number-input {
                color: @primary;
                // height: 28px;
              }
            }
            .dept-ias {
              .ant-input-number-input {
                padding: 3px 0;
              }
            }
          }
          .ias-speed-info {
            padding: 12px 0;
            .flexAlignCenter();
            gap: 4px;
            justify-content: flex-end;
            > span {
              color: @red;
              .fs-10();
              .fw-400();
              font-style: italic;
              line-height: normal;
            }
          }
        }

        /* PA/OA Leg*/
        .pa-oa-leg {
          .generate-area-content {
            .flexAlignCenter();
            justify-content: space-between;
            > span {
              color: #696b72;
              .fs-14();
              .fw-600();
              line-height: normal;
            }
            .refresh-generate-btn {
              .flexAlignCenter();
              > svg {
                cursor: pointer;
              }
            }
            .generate-btn {
              padding: 2px 11px;
              height: 28px;
              width: 94px;
              border-color: @primary;
              > span {
                color: @primary;
                .fs-14();
                .fw-500();
              }
            }
            .generate-progress-btn {
              border-radius: 4px;
              border: 0;
              background-color: #f2994a26;
              cursor: default;
              > span {
                color: @orange;
              }
            }
            .generated-area-btn,
            .generated-complt-btn {
              border-radius: 4px;
              border: 0;
              background-color: #27ae6026;
              cursor: default;
              > span {
                color: @green;
              }
            }
            .generate-failed-btn {
              border-radius: 4px;
              border: 0;
              background: #eb575726;
              > span {
                color: @red;
              }
            }
          }
          > div:nth-child(2) {
            margin: 12px 0;
            border-color: @borderclr;
          }
          .obstacle-remark-content {
            padding-top: 16px;
            .remark-label {
              .fs-14();
              .fw-600();
              color: #696b72;
              line-height: 19px;
            }
            .remark-text-area {
              margin: 8px 0;
              color: @primary;
              background-color: #f9f9f9;
              min-height: 72px;
            }
            .remarks-btn {
              display: flex;
              justify-content: flex-end;
              > button {
                > span {
                  color: @primary;
                }
              }
            }
          }
        }
        .obstacle-switch-content {
          padding-top: 16px;
          .flexAlignCenter();
          justify-content: space-between;
          > span {
            color: #696b72;
            .fs-14();
            .fw-600();
            line-height: normal;
          }
          .oa-label {
            opacity: 0.6;
          }
          .oa-checked-label {
            opacity: 1;
          }
          .obstacle-switch {
            height: 20px;
            min-width: 35px;
            background-color: @white;
            border: 1px solid @borderclr;
          }
          .oa-switch-data {
            border-color: @secondary !important;
            .ant-switch-handle {
              &::before {
                content: '';
                background-color: @secondary !important;
              }
            }
          }
          .oa-switch-data.ant-switch-checked {
            .ant-switch-handle {
              &::before {
                content: '';
                background-color: @white !important;
              }
            }
          }
          .ant-switch-checked {
            background-color: @primary;
            .ant-switch-handle {
              top: 3px;
              inset-inline-start: calc(100% - 15px);
            }
            ::before {
              content: '';
              background-color: @white;
            }
          }
          .ant-switch-handle {
            width: 12px;
            height: 12px;
            top: 3px;
          }
          ::before {
            content: '';
            background-color: @borderclr;
          }
        }
      }
    }
  }
}
.course-div {
  .flexAlignCenter();
  .ant-btn {
    width: 40px;
    padding: 4px 8px;
  }
  .course-grp-btn {
    width: 85px;
    .course-btn {
      height: 28px;
      border-color: #696b72;
      color: #696b72;
      font-size: 12px;
      font-weight: 500;
      line-height: 12px;
    }
    .course-btn:first-child {
      margin-inline-start: 8px;
    }
    .active-view-btn-t,
    .active-view-btn-m {
      color: @primary;
      border-color: @primary;
      box-shadow: 2px 0px 4px 0px rgba(0, 0, 0, 0.25);
      // background-color: @primary;
    }
    .active-view-btn-t,
    .active-view-btn-t:hover {
      border-start-end-radius: 4px;
      border-end-end-radius: 4px;
      margin-inline-end: -2px;
      z-index: 3;
      color: @primary;
      border-color: @primary;
    }
    .active-view-btn-m,
    .active-view-btn-m:hover {
      border-start-start-radius: 4px;
      border-end-start-radius: 4px;
      margin-inline-start: -2px;
      z-index: 2;
      color: @primary;
      border-color: @primary;
    }
  }
}
.error-course-div {
  .change-course-input {
    border-color: @red !important;
    .ant-input-number-input {
      color: @red !important;
    }
  }
}
.course-info {
  .flexAlignCenter();
  justify-content: flex-start;
  .course-info-text {
    color: @red;
    .fs-10();
    .fw-400();
    font-style: italic;
    line-height: normal;
  }
}
.turn-dir-btn {
  width: 184px;
  border: 0.1px solid #95969d;
  border-radius: 4px;
  > button {
    .fs-12();
    height: 28px;
    padding: 4px 15px;
    border: 0;
    color: #95969d;
    box-shadow: none;
    &:hover {
      border: 0;
      z-index: 0;
      margin-inline-start: 0;
      box-shadow: none;
    }
  }
  > button:disabled {
    background-color: @white;
    color: #95969d;
  }
  .active-dir-btn {
    color: @primary;
    border-width: 0.5px;
    border: 0.5px solid @primary;
    border-radius: 4px !important;
    box-shadow: 0px 0px 4px 1px #6e3bd140;
    z-index: 2;
    &:hover {
      color: @primary !important;
      border-radius: 4px !important;
      border: 0.5px solid @primary;
      box-shadow: 0px 0px 4px 1px #6e3bd140;
      z-index: 2;
    }
  }
}
.clearway-dist {
  .ant-input-number-input {
    color: @primary;
  }
  .ant-input-number-handler {
    display: none !important;
    z-index: 0 !important;
  }
  .ant-input-number-suffix {
    > span {
      color: @primary;
      font-size: 12px;
      font-weight: 400;
    }
    z-index: 3 !important;
  }
}
/* OMNI Leg start css */
.omni-tab-extra-content {
  .leg-tab {
    padding: 16px 3px 0px 4px !important;
  }
}
.omni-leg {
  height: calc(100% - 100px);
  .point-details {
    height: 100%;
  }
  .der-content {
    justify-content: flex-start !important;
    gap: 8px;
  }
  .start-point-main {
    margin-left: 8px;
    margin-right: 8px;
    border-radius: 4px;
    background-color: #f8f8f8;
    .start-point-content {
      padding: 0 8px;
    }
  }

  .min-distance {
    padding-top: 0 !important;
    > span:last-child {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      background-color: unset !important;
      color: @primary;
      .fs-12();
      .fw-600();
      line-height: normal;
    }
  }
  .select-length {
    padding-bottom: 0 !important;
  }
  .leg-tab {
    display: flex;
    gap: 12px;
    flex-direction: column;
  }
  .ias-speed-info {
    padding: 0 !important;
  }
  .point-btn-grp {
    > button {
      padding: 0 19.8px !important;
    }
  }
  .pa-oa-leg {
    .content-divider {
      margin: 0 !important;
    }
    .obstacle-switch-content {
      padding: 0 !important;
    }
  }
  .ant-input-number-input {
    color: @primary !important;
    padding: 4px 0 !important;
    .fs-12();
    .fw-500();
    line-height: normal;
  }
  .turn-dir-content {
    padding: 0 3px 0 4px !important;
    > input {
      background-color: #f2ecfc !important;
      border: 0 !important;
    }
  }
}
.active-grad {
  padding-top: 16px;
  border-top: 0.5px solid #c7c7cc;
  .ant-input-number {
    background-color: #f2ecfc;
  }
}
/* Omni leg css end */

/* Step 3 */
.rsid-coding-content {
  margin-top: 20px;
  .rsid-btn-main {
    padding: 0 16px;
    .flexAlignCenter();
    justify-content: space-between;
    > div:first-child {
      color: #696b72;
      .fs-14();
      .fw-600();
      line-height: normal;
    }
    > div:last-child {
      > span {
        color: #696b72;
        .fs-14();
        .fw-600();
        line-height: normal;
      }
      .coding-btn-grp {
        padding-left: 12px;
        .coding-btn {
          .flexJustifyAlignCenter();
          height: 28px;
          width: 35px;
          border: 0.5px solid #c7c7cc;
          > span {
            .fs-12();
            .fw-500();
            line-height: normal;
            color: @primary;
          }
          &:last-child {
            margin-inline-start: -3px;
          }
        }
        .on-coding-btn {
          border: 1px solid @primary;
        }
        .on-coding-table {
          background: @primary;
          > span:last-child {
            color: @white;
          }
        }
        .off-coding-table {
          border-radius: 4px;
          border: 1px solid @borderclr;
          background-color: @borderclr;
          border-start-end-radius: 4px;
          border-end-end-radius: 4px;
          box-shadow: 2px 0px 6px 0px rgba(0, 0, 0, 0.15);
          z-index: 2;
          > span {
            color: @white !important;
          }
        }
        .on-coding-table {
          border: 1px solid @primary;
          border-start-start-radius: 4px;
          border-end-start-radius: 4px;
          box-shadow: -2px 0px 6px 0px rgba(0, 0, 0, 0.15);
          z-index: 2;
        }
      }
    }
  }
}
.pa-divider {
  margin: 12px 0 0 0;
  border-block-start: 0.5px solid @borderclr;
}
.pa-generated-content {
  display: flex;
  justify-content: space-between;
  padding: 16px;
  > span {
    color: @secondary;
    .fs-16();
    .fw-600();
    line-height: 20px;
  }
  .show-content {
    display: none;
    padding-right: 10px;
    color: #696b72;
    .fs-12();
    .fw-600();
    line-height: normal;
  }
  .pa-gen-switch {
    height: 20px;
    min-width: 35px;
    background-color: @white;
    border: 1px solid @borderclr;
    &:hover {
      background-color: unset !important;
    }
  }
  .ant-switch-checked {
    background-color: @primary;
    &:hover {
      background-color: @primary !important;
    }
    .ant-switch-handle {
      top: 3px;
      inset-inline-start: calc(100% - 15px);
    }
    ::before {
      content: '';
      background-color: @white !important;
    }
  }
  .ant-switch-handle {
    width: 12px;
    height: 12px;
    top: 3px;
  }
  .ant-switch-handle::before {
    content: '';
    background-color: @borderclr;
  }
}
.pa-generated-content:hover {
  background-color: #696b720d;
  > span:first-child {
    color: @primary;
  }
  .show-content {
    display: block;
  }
}
.pa-leg-divider {
  margin: 0;
  border-block-start: 0.5px solid #95969d !important;
}
.leg-data-main {
  height: calc(100% - 148px);
  overflow: auto;
  &::-webkit-scrollbar {
    width: 3px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #696b72;
  }
}
/* Step 4 */

.oa-generated {
  padding-top: 24px;
  padding-bottom: 16px;
  text-align: center;
  color: @primary;
  .fs-16();
  .fw-600();
  line-height: 20px;
}
.oa-gen-divider {
  margin: 0;
  border-block-start: 0.5px solid #95969d !important;
}
.oa-list-div {
  height: calc(100% - 100px);
  overflow: auto;
  &::-webkit-scrollbar {
    width: 3px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #696b72;
  }
}
.alt-range-error {
  .ant-input-number-input,
  .ant-input-number-suffix {
    color: @red !important;
  }
}

/* aero report sidebar css */
.aero-report-sidebar {
  .report-sidebar-content {
    height: calc(100% - 125px - 58px);
    overflow: auto;
    .scrollbarW3();
  }
  .report-btn-content {
    height: 125px;
    width: 100%;
    .flexDirectionColumn();
    > div {
      > button {
        height: 28px;
        padding: 0;
        width: 132px;
      }
      padding-top: 16px;
      padding-bottom: 16px;
      .flexAlignCenter();
      justify-content: space-evenly;
      &:last-child {
        .borderTop();
        > button {
          width: 159px;
          height: 32px;
        }
      }
    }
  }
}

@primary-color: #6E3BD1;@page-header-heading-title: 24px;@btn-border-radius-base: 4px;@btn-border-radius-sm: 4px;