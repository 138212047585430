.icon-ruler {
  cursor: pointer;
  background-color: var(--secondary) !important;
  background-image: url(/src/assets/png/rulericonwhite.png) !important;
  width: 24px !important;
  height: 24px !important;
}

.icon-active {
  background-color: var(--white) !important;
  background-image: url(/src/assets/png/rulericonblack.png) !important;
}

.ruler-map {
  cursor: crosshair !important;
}

.leafler-ruler-bar {
  position: absolute;
  top: -220px !important;
  right: 0;
  border: 0 !important;
  margin-right: 8px !important;
  margin-bottom: 8px !important;
}

.total-popup {
  width: auto !important;
  height: auto !important;
  padding-left: 15px;
  margin-top: -10px !important;
  background-color: transparent;
}

.total-popup-content {
  padding: 1px 7px;
  background-color: var(--primary) !important;
  border-radius: 8px;
  color: var(--white) !important;
  white-space: nowrap;
  text-align: center;
  margin-top: 15px;
}

.total-popup-content .poly-close {
  display: none;
}

.total-popup-content .poly-close:hover {
  opacity: 0.7;
}

.total-popup-content:hover .poly-close {
  display: inline;
  margin-left: 10px;
  position: relative;
  cursor: pointer;
}

.total-popup-content svg {
  width: 15px;
  height: 10px;
  position: relative !important;
  top: 0px !important;
  left: 5px !important;
}

.total-popup-content svg path {
  stroke: var(--white);
  fill: transparent;
  stroke-linecap: round;
  stroke-width: 7;
}

.total-popup-content svg:hover {
  opacity: 0.7;
}

.total-popup-content svg:active {
  opacity: 0.3;
}

.total-popup-label {
  padding: 5px;
  padding-top: 10px;
  background-color: transparent;
  color: var(--white);
  font-size: 10px;
  white-space: nowrap;
}

.dot-label {
  white-space: nowrap;
  font-size: 11px;
  white-space: nowrap;
  background: #1ee0cd;
  padding: 1px 7px;
  border-radius: 8px;
  margin-left: -15px;
}

.not-show {
  display: none;
  background: none !important;
}

.latlng-label {
  font-size: 10px;
}

.node-label-text {
  background-color: transparent;
  line-height: 1 !important;
}

.node-label {
  top: -25px !important;
}

.azimut {
  color: blue;
  text-shadow: 1px 1px 0px var(--white);
  font-size: 13px;
  font-weight: normal;
}

.azimut-final {
  text-shadow: none;
  font-weight: bold;
}
