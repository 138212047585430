@import 'styles/variable.less';

/* Aerodrome Obsacles Table */
.aerodrome-details {
  position: absolute;
  bottom: 40px;
  right: 48px;
  z-index: 1000;
  width: calc(100% - 64px);
  height: 330px;
  border-radius: 4px;
  border: 0.5px solid @borderclr;
  background-color: @white;
  .aerodrome-header {
    padding: 0 16px 0 22px;
    height: 38px;
    .flexAlignCenter();
    justify-content: space-between;
    box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.1);
    > div {
      .flexAlignCenter();
      > span {
        padding-right: 8px;
        color: @primary;
        .fs-16();
        .fw-600();
      }
      > svg {
        cursor: pointer;
      }
    }
  }
  > div:last-child {
    .aero-obstacle-table {
      table {
        thead {
          tr {
            th {
              padding-top: 0px !important;
              padding-bottom: 0px !important;
              height: 36px;
              .fs-12();
              .fw-600() !important;
              color: @grayshade !important;
              background-color: #f7f3ff;
            }
          }
        }
        tbody {
          tr {
            td {
              color: #696b72;
              .fs-12();
              .fw-500();
              height: 46px;
            }
          }
        }
      }
    }
  }
}

/* Obstacle Analysis Table*/
.obstacle-details {
  position: absolute;
  bottom: 24px;
  right: 48px;
  z-index: 1000;
  width: calc(100% - 64px);
  height: 330px;
  border-radius: 4px 4px 0px 0px;
  border: 0.5px solid @borderclr;
  background-color: @white;
  .obstacle-header {
    padding: 0 16px 0 22px;
    height: 38px;
    .flexAlignCenter();
    justify-content: space-between;
    > div:first-child {
      .flexAlignCenter();
      > span {
        padding-right: 8px;
        color: @primary;
        .fs-16();
        .fw-600();
      }
      > svg {
        cursor: pointer;
      }
    }
    .obs-terrain-btn {
      .obs-btn,
      .terrain-btn {
        max-height: 25px;
        padding: 2px 10px;
        margin-inline-start: -3px;
        .fs-12();
        .fw-400();
        line-height: normal;
        &:hover {
          z-index: 1;
        }
      }
      .active-btn {
        border-color: @primary;
        color: @primary;
        box-shadow: 5px 0 5px -5px rgba(0, 0, 0, 0.02);
      }
      .obs-btn.active-btn,
      .obs-btn.active-btn:hover {
        border-start-end-radius: 4px;
        border-end-end-radius: 4px;
        z-index: 2;
      }
      .terrain-btn.active-btn,
      .terrain-btn.active-btn:hover {
        border-start-start-radius: 4px;
        border-end-start-radius: 4px;
        z-index: 2;
      }
    }
    > div:last-child {
      .flexAlignCenter();
      > span {
        padding-right: 8px;
        color: #696b72;
        .fs-14();
        .fw-600();
      }

      .oa-result-switch {
        background-color: @white;
        border: 1px solid @borderclr;
        margin-right: 16px;
        height: 20px;
        width: 35px;
        &:hover {
          background-color: unset !important;
        }
      }
      .ant-switch-checked {
        background-color: @primary;
        &:hover {
          background-color: @primary !important;
        }
        .ant-switch-handle {
          top: 3px;
          inset-inline-start: calc(100% - 14px) !important;
        }
        ::before {
          content: '';
          background-color: @white !important;
        }
      }
      .ant-switch-handle {
        top: 3px;
      }
      .ant-switch-handle::before {
        content: '';
        background-color: @borderclr;
      }
    }
  }
  > div:last-child {
    .obstacle-analysis-table {
      table {
        thead {
          tr {
            th {
              padding-top: 0px !important;
              padding-bottom: 0px !important;
              height: 36px;
              .fs-12();
              .fw-600() !important;
              color: @grayshade !important;
              background-color: #f7f3ff;
            }
          }
        }
        tbody {
          tr {
            td {
              color: #696b72;
              .fs-12();
              .fw-500();
              height: 46px;
            }
          }
        }
      }
    }
  }
}

/* Coding Table */
.full-coding-details {
  height: 100% !important;
  position: unset !important;
  width: 100% !important;
  border-radius: 4px !important;
  .full-collect-table {
    border: 0 !important;
  }
}
.coding-details {
  position: absolute;
  bottom: 24px;
  right: 48px;
  z-index: 1000;
  width: calc(100% - 64px);
  height: 330px;
  border-radius: 4px 4px 0px 0px;
  border: 0.5px solid @borderclr;
  background-color: @white;
  .coding-header {
    padding: 0 16px 0 22px;
    height: 38px;
    .flexAlignCenter();
    justify-content: space-between;
    > div {
      .flexAlignCenter();
      > span {
        padding-right: 8px;
        color: @primary;
        .fs-16();
        .fw-600();
      }
      > svg {
        cursor: pointer;
      }
    }
  }
  > div:last-child {
    .coding-table {
      table {
        thead {
          tr {
            th {
              padding: 0 !important;
              height: 36px;
              .fs-12();
              .fw-600() !important;
              color: #696b72 !important;
              background-color: #f7f3ff;
            }
          }
        }
        tbody {
          tr {
            td {
              padding: 0 !important;
              color: #696b72;
              .fs-12();
              .fw-500();
              height: 46px;
            }
          }
        }
      }
    }
  }
}

@primary-color: #6E3BD1;@page-header-heading-title: 24px;@btn-border-radius-base: 4px;@btn-border-radius-sm: 4px;