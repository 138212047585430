@import 'styles/variable.less';

.design-data-map-container {
  cursor: crosshair !important;
  .leaflet-control {
    position: absolute;
    top: 0;
  }
}
.design-data-content {
  position: relative;
}
.leaflet-draw {
  position: absolute;
  top: 40px !important;
  right: 0;
}
.ad-data-layers {
  overflow: auto;
  height: calc(100% - 38px);
  &::-webkit-scrollbar {
    width: 0;
    height: 5px;
    background-color: @white;
  }
  &::-webkit-scrollbar-thumb {
    background: @secondary;
    border-radius: 3px;
  }
  .ad-data-table-main {
    > table {
      > thead {
        > tr {
          > th {
            color: #95969d !important;
            background-color: #f7f3ff !important;
          }
        }
      }
    }
  }
}
.design-full-map-drawer {
  height: calc(100% - 56px) !important;
  position: absolute;
  top: 56px;
  .ant-drawer-header {
    display: none;
  }
  .ant-drawer-body {
    padding: 0;
  }
}

@primary-color: #6E3BD1;@page-header-heading-title: 24px;@btn-border-radius-base: 4px;@btn-border-radius-sm: 4px;