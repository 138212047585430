@import 'styles/variable.less';

.ad-data-table {
  width: 100%;
  > tbody {
    > tr {
      height: 44px;
      border-bottom: 1px solid #f0f0f0;
      &:last-child {
        border-bottom: 0;
      }
      > td {
        color: #696b72;
        padding: 6px 10px;
      }
       &:hover{
        background-color: #fafafa;
      }
    }
  }
}

.ad-data-table2 {
  width: 100%;
  > thead {
    > tr {
      height: 44px;
      > th {
        padding: 5px;
        border-bottom: 0;
        background-color: #f3f3f3;
        position: sticky;
        top: 0;
      }
    }
  }
  > tbody {
    > tr {
      height: 44px;
      border-bottom: 1px solid #f0f0f0;
      &:last-child {
        border-bottom: 0;
      }
      > td {
        text-align: center;
        color: #696b72;
        padding: 6px 10px;
      }
      &:hover {
        background-color: #fafafa;
      }
    }
  }
}

// Navaid Item
.navaids-table {
  width: 100%;
  > thead {
    > tr {
      height: 44px;
      > th {
        padding: 5px;
        border-bottom: 0;
        background-color: #f3f3f3;
        position: sticky;
        top: 0;
      }
    }
  }
  > tbody {
    > tr {
      height: 44px;
      border-bottom: 1px solid #f0f0f0;
      &:last-child {
        border-bottom: 0;
      }
      > td {
        color: #696b72;
        padding: 6px 10px;
        font-size: 12px;
        width: 100px;
        > table {
          > tbody {
            > tr {
              td {
                color: #696b72;
                font-size: 12px;
              }
            }
          }
        }
      }
      .navid-type {
        background-color: #6e3bd1c2;
        color: @white;
      }
    }
  }
}
.data-size-color {
  font-size: 12px;
  color: #696b72;
}

// Approach runway lighting
.rwylights-table {
  width: 100%;
  > thead {
    > tr {
      height: 44px;
      > th {
        padding: 5px;
        border-bottom: 0;
        background-color: #f3f3f3;
        position: sticky;
        top: 0;
      }
    }
  }
  > tbody {
    > tr {
      height: 44px;
      border-bottom: 1px solid #f0f0f0;
      &:last-child {
        border-bottom: 0;
      }
      > td {
        text-align: center;
        color: #696b72;
        padding: 6px 10px;
        font-size: 12px;
        width: 100px;
        > table {
          > tbody {
            > tr {
              td {
                color: #696b72;
                font-size: 12px;
              }
            }
          }
        }
      }
    }
  }
}

@primary-color: #6E3BD1;@page-header-heading-title: 24px;@btn-border-radius-base: 4px;@btn-border-radius-sm: 4px;