.alert-content {
  position: absolute;
  z-index: 1011;
  top: 56px;
  right: 10px;
  > div {
    padding-inline: 15px;
    padding-block: 10px;
  }
}

@primary-color: #6E3BD1;@page-header-heading-title: 24px;@btn-border-radius-base: 4px;@btn-border-radius-sm: 4px;