@import 'styles/variable.less';

.user-mng-label {
  .flexAlignCenter();
  gap: 8px;
  > button {
    margin-left: -40px;
  }
}
.user-mng-assignee {
  display: flex;
  align-items: center;
  .assigne-avatar {
    background-color: @white;
    color: #696b72;
    border: 1px solid #696b72;
    .fs-12();
    .fw-500();
  }
  .assignee-label {
    padding-left: 8px;
    color: #696b72;
    .fs-14();
    .fw-500();
  }
}
.user-mng-actions {
  display: flex;
  justify-content: space-evenly;
  align-items: baseline;
  .ant-switch-checked {
    background-color: @green;
    &:hover {
      background-color: @green !important;
    }
  }
}
// .add-user-input-email {
//   .ant-form-item-explain-error {
//     display: none;
//   }
// }

@primary-color: #6E3BD1;@page-header-heading-title: 24px;@btn-border-radius-base: 4px;@btn-border-radius-sm: 4px;