@import 'styles/variable.less';

/* Header 1 */
.header-div {
  .flexAlignCenter();
  justify-content: space-between;
  height: 100%;
  .header-title {
    color: @secondary;
    text-align: center;
    .fs-22();
    .fw-700();
  }
  > svg {
    cursor: pointer;
  }
  .ant-dropdown-open {
    border: 1px solid @primary;
    border-radius: 50%;
    padding: 4px;
    > span {
      > svg {
        > path {
          fill: @primary !important;
        }
      }
    }
  }
  .user-menu {
    width: 30px;
    .flexJustifyAlignCenter();
    > span {
      > svg:hover {
        path {
          fill: @primary;
        }
      }
    }
  }
}
.user-menu-item {
  .ant-dropdown-menu {
    border-radius: 2px !important;
    min-width: 230px;
  }
}
.user-detail {
  .flexAlignCenter();
  gap: 10px;
  padding-bottom: 5px;
  border-bottom: 1px solid @primary;
  > span:first-child {
    background-color: @primary;
  }
  > div {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    :first-child {
      color: @primary;
      .fs-16();
      .fw-500();
    }
    :last-child {
      color: @primary;
      .fw-400();
      .fs-14();
    }
  }
}
.profile-setting {
  .flexAlignCenter();
  width: 100%;
  > span:last-child {
    padding-left: 10px;
    .fw-500();
    .fs-14();
    line-height: 17px;
    color: @tooltiptitle;
  }
}

/* Header 2 */
.header-div {
  .left-side-content {
    height: inherit;
    .flexAlignCenter();
    justify-content: space-between;
    > svg:first-child {
      margin-right: 35px;
      cursor: pointer;
    }
    .list-link {
      position: relative;
      padding-left: 35px;
      height: inherit;
      .flexAlignCenter();
      justify-content: flex-start;
      gap: 12px;
      &::before {
        content: '';
        position: absolute;
        width: 1px;
        left: 0;
        height: 40px;
        border: 1px solid @grayshade;
      }
      .nav-link {
        height: inherit;
        padding-inline: 23px;
        .fs-14();
        .fw-600();
        line-height: 55px;
        color: @grayshade;
        text-transform: uppercase;
        @media (max-width: 1100px) {
          padding-inline: 17px;
        }
      }
      .active-link {
        border-bottom: 3px solid @primary;
        color: @primary;
        .fw-700();
      }
    }
  }
  .header-icons {
    .flexAlignCenter();
    flex-direction: row;
    gap: 18px;
    > svg {
      cursor: pointer;
    }
  }
}

@primary-color: #6E3BD1;@page-header-heading-title: 24px;@btn-border-radius-base: 4px;@btn-border-radius-sm: 4px;