@import 'styles/variable.less';

.design-status {
  .flexJustifyCenter();
  width: 80px;
  border-radius: 2px;
  color: @white;
  .fs-12();
  .fw-600();
  line-height: 24px;
  letter-spacing: 0.12px;
}

/* Required Title*/
.input-label {
  width: 100%;
  .fw-500();
  .fs-20();
  line-height: 24px;
  color: @titleColor;
}

/* Checkbox card btn */
.chk-button-main {
  border-radius: 4px;
  border: 0.5px solid @grayshade;
  background: @white;
  border-radius: 4px;
  .flexAlignCenter();
  flex-direction: row;
  padding: 0px 16px;
  gap: 8px;
  height: 32px;
  cursor: pointer;
  &:hover {
    box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.15);
    .chk-card {
      opacity: 1;
    }
  }
  .chk-card {
    opacity: 0;
  }
  > span:last-child {
    color: @grayshade;
    .fs-12();
    .fw-500();
  }
}
.box-checked {
  border: 0.5px solid @primary;
  > span:last-child {
    color: @primary;
    .fs-12();
    .fw-500();
  }
}
.click-btn-main {
  background: @primary;
  .chk-card {
    opacity: 1;
    > span {
      :last-child {
        background-color: @primary;
      }
    }
  }
  .is-checked {
    opacity: 1;
    > span {
      :last-child {
        background-color: white;
        &::after {
          border-color: @primary !important;
        }
      }
    }
  }
  > span:last-child {
    color: @white;
  }
}

/*  Empty Text  */
.empty-label {
  .fs-22();
  .fw-700();
  color: #696b72;
  text-transform: unset !important;
}

/*  Protection Area Switch  */
.pa-leg-content {
  .flexAlignCenter();
  justify-content: space-between;
  padding: 16px 16px 14px;
  .pa-leg-label {
    color: @secondary;
    .fs-14();
    .fw-600();
    line-height: normal;
    text-transform: uppercase;
  }
  .oa-switch {
    background-color: @white;
    border: 1px solid @borderclr;
    &:hover {
      background-color: unset !important;
    }
  }
  .ant-switch-checked {
    background-color: @primary;
    &:hover {
      background-color: @primary !important;
    }
    .ant-switch-handle {
      top: 2px;
      width: 10px;
      height: 10px;
      inset-inline-start: calc(100% - 12px) !important;
    }
    ::before {
      content: '';
      background-color: @white !important;
    }
  }
  .ant-switch-handle {
    width: 10px;
    height: 10px;
    top: 1px;
  }
  .ant-switch-handle::before {
    content: '';
    background-color: @borderclr;
  }
}
.pa-content-main:hover {
  background-color: #696b720d;
}
.leg-divider {
  height: 100%;
}
.leg-content {
  > div {
    .flexAlignCenter();
    justify-content: space-between;
    margin-bottom: 12px;
    > div {
      .fs-12();
      line-height: normal;
      &:first-child {
        .fw-500();
        color: #696b72;
      }
      &:last-child {
        .fw-600();
        color: @secondary;
      }
    }
    &:last-child {
      margin-bottom: 0;
    }
  }
}
.obstacle-switch-label {
  font-weight: 600;
  font-size: 12px;
  color: #696b72;
}

/*  Obstacle  Analysis Switch  */
.oa-leg-content-main {
  border-bottom: 0.5px solid @borderclr;
  .show-oa-content {
    padding-right: 16px;
    color: #696b72;
    .fs-12();
    .fw-600();
    line-height: normal;
    display: none;
  }

  .oa-content-main:hover {
    background-color: #696b720d;
    .show-oa-content {
      display: block;
    }
  }
  .checked-oa-content {
    display: block;
  }
}

/* confirmation modal */
.confirmation-modal {
  .ant-modal-content {
    padding: 0;
    .ant-modal-close {
      top: 10px;
    }
    .ant-modal-header {
      margin: 0;
      padding-top: 9px;
      padding-bottom: 9px;
      padding-left: 9px;
      background: #fff;
    }
    .ant-modal-body {
      padding: 15px 16px 15px;
      border-top: 1px solid #f2f2f2;
      .modal-desc {
        color: #696b72;
        .fs-14();
        .fw-400();
        line-height: normal;
      }
    }
    .ant-modal-footer {
      padding-right: 16px;
      padding-top: 16px;
      padding-bottom: 16px;
      margin: 0;
      border-top: 1px solid #f2f2f2;
      .ant-btn-primary {
        margin-inline-start: 16px !important;
      }
    }
  }
}

/* Report Content Card css*/
.report-data {
  .flexAlignCenter();
  justify-content: space-between;
  > div {
    .flexAlignCenter();
    gap: 12px;
  }
  .icon-btn {
    background-color: @headerBackClr;
    height: 36px;
    width: 36px;
    .flexJustifyAlignCenter();
    cursor: default;
  }
  .report-title {
    font-family: Manrope;
    .fs-14();
    .fw-700();
    line-height: 20px;
    text-transform: uppercase;
    color: @primary;
  }
}

/* list data card */
.list-data-card {
  min-height: 55px;
  width: 264px;
  background-color: #fbfbfb;
  padding: 6px 12px;
  .flexDirectionColumn();
  gap: 6px;
  border-radius: 4px;
  position: relative;
  > span:first-child {
    font-family: Manrope;
    .fs-12();
    .fw-400();
    line-height: 16.39px;
    color: @secondary;
    .flexAlignCenter();
  }
  .tital-info {
    font-family: Verdana;
    font-style: italic;
    .fs-10();
    .fw-400();
    line-height: 12.15px;
    color: @red;
    padding-left: 12px;
    .flexJustifyAlignCenter();
    display: inline-flex;
    gap: 5px;
  }
  > div {
    .flexDirectionColumn();
    gap: 6px;
    > span,
    .select-title {
      .fs-14();
      .fw-600();
      line-height: 19.12px;
      color: @primary;
    }
  }
  .list-card-info {
    position: absolute;
    font-style: italic;
    .fs-10();
    .fw-400();
    line-height: 12.15px;
    color: @red;
    bottom: -16px;
    .flexAlignCenter();
    left: 0;
    >svg{
      margin-right: 5px;
    }
  }
  .ant-input {
    border: 0;
    color: @primary;
    background-color: #fbfbfb;
    padding-left: 0;
  }
  .airspace-select {
    border: 0;
    .ant-select-selector,
    .ant-select-selection-search-input {
      height: 28px !important;
    }
    .ant-select-selector {
      border: 0;
      background-color: #fbfbfb;
    }
    .ant-select-selection-item {
      .fs-12();
      color: @primary;
      .fw-600();
    }
    &:hover {
      border: 0;
    }
  }
}

@primary-color: #6E3BD1;@page-header-heading-title: 24px;@btn-border-radius-base: 4px;@btn-border-radius-sm: 4px;