@import 'styles/variable.less';

.design-table-main {
  .ant-table-body {
    overflow: auto !important;
    &::-webkit-scrollbar {
      width: 6px;
      height: 5px;
      background-color: @white;
    }
    &::-webkit-scrollbar-thumb {
      background-color: @ghostbtnBack;
      border-radius: 3px;
    }
  }
  table {
    thead {
      tr {
        th {
          padding-top: 0px !important;
          padding-bottom: 0px !important;
          height: 48px;
          .fs-14();
          .fw-700() !important;
          color: @grayshade !important;
          &::before {
            display: none;
          }
          @media (max-width: 1400px) {
            padding-top: 10px;
            padding-bottom: 10px;
            .fs-12();
          }
          @media (max-width: 1024px) {
            padding-top: 5px;
            padding-bottom: 5px;
            font-size: 11px;
          }
        }
      }
    }
    tbody {
      tr {
        td {
          .fs-14();
          padding-top: 3px !important;
          padding-bottom: 3px !important;
          height: 56px;
          &:first-child {
            .ant-table-row-expand-icon {
              margin-bottom: 6px;
            }
            > span:last-child {
              text-transform: uppercase;
              letter-spacing: 0.14px;
            }
          }
          @media (max-width: 1200px) {
            padding-top: 5px;
            padding-bottom: 5px;
            .fs-12();
          }
        }
        .airport-name {
          padding-left: 40px !important;
        }
      }
    }
  }
}
.arp-title,
.cfn-code,
.type-procedure,
.name-procedure,
.all-procedures {
  .fs-14();
  line-height: 24px;
  letter-spacing: 0.14px;
}
.arp-title {
  color: @secondary;
  .fw-600();
}
.cfn-code {
  color: @secondary;
  .fw-500();
}
.type-procedure,
.name-procedure,
.all-procedures {
  color: #696b72;
  .fw-500();
}
.all-procedures {
  .flexJustifyAlignCenter();
  gap: 2px;
  .procedure-info {
    border-radius: 50%;
    width: 30px;
    height: 30px;
    .flexJustifyAlignCenter();
    cursor: pointer;
    position: relative;
    &:hover {
      background-color: #696b7218;
    }
  }
  .ant-dropdown-open {
    box-shadow: 4px 4px 12px rgba(0, 0, 0, 0.15);
  }
}
.procedure-dropdown {
  .procedure-dropdown-div {
    width: 232px;
    border-radius: 4px;
    background-color: @white;
    box-shadow: 0px 6px 18px 0px rgba(0, 0, 0, 0.2);
    padding: 8px 0;
    .dropdown-title {
      .flexJustifyAlignCenter();
      justify-content: space-between;
      padding: 8px;
      // padding-bottom: 4px;
      border-bottom: 0.5px solid @borderclr;
      > span {
        color: @secondary;
        .fs-12();
        .fw-600();
        text-shadow: none;
      }
      > svg {
        cursor: pointer;
      }
    }
    .procedure-lists {
      list-style: none;
      margin: 0;
      padding-left: 0;
      max-height: 350px;
      overflow: auto;
      > li {
        color: @secondary;
        padding: 12px 8px;
        width: 100%;
        text-decoration: none;
        border-bottom: 0.5px dashed @borderclr;

        &:last-child {
          border: 0;
          padding-bottom: 4px;
        }
        &:hover {
          background: #696b7218;
          cursor: pointer;
        }
        > a {
          color: #696b72;
          .fs-12();
          .fw-500();
          display: inline-block;
          width: inherit;
        }
      }
    }
  }
}
.last-update-main {
  display: flex;
  flex-direction: column;

  .last-update {
    color: @grayshade;
    .fs-14();
    .fw-500();
  }
}
.marker-detail-table {
  .design-table-main {
    table {
      thead {
        tr {
          th {
            &:first-child {
              padding-left: 32px;
            }
          }
        }
      }
      tbody {
        tr {
          td {
            &:first-child {
              padding-left: 32px;
            }
          }
        }
      }
    }
  }
}
.ant-table-expanded-row {
  .ant-table {
    margin-block: 0px !important;
    table {
      thead {
        tr {
          th {
            padding-top: 0px !important;
            padding-bottom: 0px !important;
            height: 48px;
            .fs-14();
            .fw-700() !important;
            color: @grayshade !important;
            &::before {
              display: none;
            }
            @media (max-width: 1400px) {
              padding-top: 10px;
              padding-bottom: 10px;
              .fs-12();
            }
            @media (max-width: 1024px) {
              padding-top: 5px;
              padding-bottom: 5px;
              font-size: 11px;
            }
          }
        }
      }
      tbody {
        tr {
          td {
            &:nth-child(1) {
              border-bottom: 0 !important;
            }
          }
        }
      }
    }
  }
}
.home-search-div,
.home-btns-div {
  position: absolute;
  top: 16px;
}
.home-search-div {
  left: 16px;
}
.home-btns-div {
  right: 16px;
}
.search-input-div {
  .flexAlignCenter();
  justify-content: flex-start;
  .subheader-title {
    word-break: normal;
    padding-right: 24px;
    color: @secondary;
    .fs-22();
    .fw-600();
    line-height: 32px;
  }
  .search-input {
    width: 356px;
    height: 32px;
    border-radius: 4px;
    background: @white;
    box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.1);
  }
}

.create-list-div {
  .flexAlignCenter();
  justify-content: space-between;
  gap: 24px;
  .list-map-grp-btn {
    > button:first-child {
      border-radius: 4px 0px 0px 4px;
    }
    > button:last-child {
      border-radius: 0px 4px 4px 0px;
    }
    .active-view-btn {
      background-color: @primary;
      border-color: @primary;
      border-radius: 4px;
      > svg {
        fill: @white !important;
      }
    }
  }
}
