@import 'styles/variable.less';

.leaflet-control-layers {
  opacity: 0 !important;
}
.map-action-control {
  position: absolute;
  z-index: 1000;
  right: 8px;
  bottom: 28px;
  .map-layer,
  .zoom-plus,
  .zoom-minus,
  .map-recenter,
  .map-eye,
  .map-full-screen {
    cursor: pointer;
  }
  .map-eye {
    height: 24px;
    background-color: @secondary;
    .flexJustifyAlignCenter();
  }
}
.maplayer-dropdown {
  > ul {
    min-width: 200px !important;
    padding: 0 !important;
    border-radius: 4px !important;
    background-color: rgba(37, 37, 37, 0.75) !important;
    .ant-dropdown-menu-item {
      padding: 5px;
      &:hover {
        background: unset;
      }
    }
    .ant-typography {
      color: @white;
    }
  }
}
/* Live Coordinates */
.current-position {
  position: absolute;
  font-family: 'Manrope' !important;
  z-index: 1000;
  bottom: 0;
  width: 100%;
  .flexAlignCenter();
  justify-content: space-between;
  height: 24px;
  padding: 0px 16px 0px 8px;
  background-color: @avatarTitle;
  > div:first-child {
    .fw-500();
    .divspan();
  }
  > div:last-child {
    .fw-600();
    .divspan();
  }
}
.divspan {
  color: @white;
  font: 600 14px/20px 'Manrope' !important;
}
.marker-sticky-tooltip {
  background-color: unset;
  border: 0;
  box-shadow: none;
  padding: 0;
  border-radius: 0;
  color: @white;
  text-align: center;
  font: 600 12px/20px 'Manrope' !important;
  &::before {
    display: none;
  }
}

.waypoint-tooltip {
  color: @white !important;
  font-weight: 700 !important;
}

/* Polygon popup */
.polygon-popup {
  > div:first-child {
    background: rgba(255, 255, 255, 0.9);
    border-radius: 4px;
    > div:first-child {
      width: 232px !important;
      > div {
        > div {
          > div {
            gap: 0px !important;
          }
        }
      }
      .tooltip-title {
        .fw-600();
        .fslh-12();
        color: @secondary;
      }
      .tooltip-content {
        .fw-500();
        .fslh-12();
        color: @primary;
      }
    }
  }
  > div:nth-child(2) {
    > div {
      display: none;
    }
  }
  > a:last-child {
    top: 2px;
    right: 3px;
  }
}

.point-popup {
  left: 0px !important;
  bottom: -298px !important;
  > div:first-child {
    background: rgba(255, 255, 255, 0.9);
    border-radius: 4px;
    > div {
      margin: 12px 15px;
      margin-top: 25px;
      border: 0;
      &::-webkit-scrollbar {
        width: 5px;
        background-color: var(--white);
      }
      &::-webkit-scrollbar-thumb {
        background: var(--secondary);
        border-radius: 3px;
      }
      > div {
        gap: 10px;
        .market-title {
          .fw-600() !important;
          .fs-12();
          line-height: 16px;
          color: @secondary;
          display: block;
          .ff();
          letter-spacing: 0.5px;
        }
        .market-data {
          .fw-500() !important;
          .fs-12();
          line-height: 16px;
          color: @primary;
          display: block;
          .ff();
          letter-spacing: 0.5px;
        }
      }
    }
  }
  > div:nth-child(2) {
    display: none;
  }
  > a:last-child {
    top: 3px;
    right: 3px;
    color: @black;
    &:hover {
      color: @black;
    }
  }
}

.omni-obstacle,
.ad-obstacle {
  .leaflet-popup-content {
    display: flex;
    flex-direction: column;
    .close-btn {
      position: absolute;
      top: 6px;
      right: 14px;
      padding: 0;
      .ff();
      height: 16px;
      line-height: 14px;
      .fs-14();
      .fw-700();
    }
  }
}
.center-point-data {
  background-color: transparent;
  border: 0;
  box-shadow: none;
  color: #696b72;
  &::before {
    display: none;
  }
  margin: 0;
  padding: 0;
  .mid-distance-data {
    margin-top: 5px;
  }
  .mid-bearing-data {
    margin-top: 65px;
  }
}

@primary-color: #6E3BD1;@page-header-heading-title: 24px;@btn-border-radius-base: 4px;@btn-border-radius-sm: 4px;