@import 'styles/variable.less';
@import 'styles/image.less';

.main-login-page {
  background-image: url(@loginBackground);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  display: flex;
  justify-content: flex-end;
  height: 100vh;
  .satsure-skies-logo {
    position: absolute;
    top: 96px;
    left: 90px;
    @media (max-width: 1024px) {
      top: 76px;
      left: 70px;
    }
    @media (max-width: 768px) {
      top: 46px;
      left: 40px;
    }
  }
  .login-content {
    background: @white;
    width: 480px;
    height: 100vh;
    display: flex;
    flex-direction: column;
    gap: 112px;
    justify-content: center;
    @media (max-width: 1200px) {
      width: 440px;
      gap: 80px;
    }
    @media (max-width: 1024px) {
      width: 400px;
      gap: 60px;
    }
    @media (max-width: 768px) {
      width: 350px;
      gap: 40px;
    }
    .welcome-label {
      text-align: center;
      margin-bottom: 56px;
      :first-child {
        padding: 30px 0;
        margin: 0;
        .fs-24();
        .fw-700();
        color: @secondary;
      }
      :last-child {
        padding: 0 80px;
        margin: 0;
        .fs-24();
        .fw-700();
        color: @secondary;
        @media (max-width: 1200px) {
          padding: 0 60px;
        }
        @media (max-width: 1024px) {
          padding: 0 45px;
        }
        @media (max-width: 768px) {
          padding: 0 20px;
        }
      }
    }
    .login-form {
      padding: 0 105px;
      @media (max-width: 1024px) {
        padding: 0 50px;
      }
      .ann-form-label {
        .fw-600();
        .fs-16();
        line-height: 16px;
        color: #696b72;
      }
      .email-input {
        margin-bottom: 36px;
        .form-input {
          border: 0;
          border-bottom: 1px solid @btnTitle;
          padding: 10px 0px 7px;
          border-radius: 0;
          color: @primary;
          .fw-400();
          .fs-14();
          line-height: 22px;
        }
      }
      .password-input {
        .form-input {
          border: 0;
          border-radius: 0;
          border-bottom: 1px solid @btnTitle;
          padding: 10px 0 7px;
          margin: 0;
          > input {
            color: @primary;
            .fw-400();
            .fs-14();
          }
        }
      }
      .login-btn {
        border-radius: 4px;
        box-shadow: none;
        border: 0;
        padding: 0;
        margin-top: 52px;
        > span {
          .fw-600();
          .fs-14();
          line-height: 24px;
          color: @white;
        }
      }
      .forgot-password {
        > button {
          padding: 0;
          color: #9961f5;
          > span {
            .fw-600();
            font-size: 15px;
            line-height: 18px;
          }
        }
      }
    }
    .satsure-logo {
      .flexAlignCenter();
      flex-direction: column;
      padding-bottom: 30px;
      @media (max-height: 700px) {
        padding-top: 0;
      }
      > span {
        color: @grayshade;
        .fs-12();
        .fw-500();
      }
    }
  }
}

@primary-color: #6E3BD1;@page-header-heading-title: 24px;@btn-border-radius-base: 4px;@btn-border-radius-sm: 4px;