@import 'styles/variable.less';
@import 'styles/main.less';

.main-design-list {
    height: 100%;
    width: 100%;
  .review-design-list {
    width: 100%;
    height: 100%;
    padding: 0px 16px;
  }
}
    
@primary-color: #6E3BD1;@page-header-heading-title: 24px;@btn-border-radius-base: 4px;@btn-border-radius-sm: 4px;