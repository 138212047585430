@import 'styles/variable.less';

/* Create New Design Modal */
.create-design-modal {
  .ant-modal-content {
    border-radius: 4px;
  }
  .create-modal-content {
    width: 100%;
    height: 417px;
    padding-top: 137px;
    padding-bottom: 32px;
    justify-content: space-between;
    align-items: center;
    .modal-content-title {
      color: @secondary;
      text-align: center;
      .fs-22();
      .fw-700();
      line-height: normal;
    }
    > div:last-child {
      > div {
        gap: 16px !important;
        > div {
          > button {
            border-radius: 4px;
          }
        }
      }
    }
  }
}

/* New Design Assign Modal */
.new-assign-modal {
  > div:nth-child(2) {
    border-radius: 4px;
    background: @white;
    padding: 0;
    > div:nth-child(2) {
      border-radius: 0px;
      border-bottom: 0.5px solid @borderclr;
      margin: 0;
      padding: 15px 24px;
      > div {
        color: @secondary;
        .fs-18();
        .fw-600();
        line-height: 24px;
      }
    }
    .assign-modal-body {
      padding: 0 24px;
    }
    .ant-modal-footer {
      padding: 16px 24px;
      > button {
        height: 32px;
        width: 112px;
        border-radius: 4px;
      }
      > button:last-child {
        margin-inline-start: 16px !important;
      }
    }
  }
}

/* Departure-Omni Procedure Modal */
.procedure-modal {
  .ant-modal-content {
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    padding: 0;
    .ant-modal-close{
      top: 10px;
    }
    .ant-modal-header {
      padding: 9px 16px;
      border-bottom: 1px solid @white1;
      margin-bottom: 0;
      .ant-modal-title {
        color: @secondary;
        .fs-16();
        .fw-600();
        line-height: normal;
      }
    }
    .ant-modal-body {
      padding: 15px 16px 25px 16px;
      > div {
        color: #696b72;
        .fs-14();
        .fw-400();
        line-height: normal;
        > b {
          color: @secondary;
          .fs-14();
          .fw-600();
          line-height: normal;
        }
      }
    }
    .ant-modal-footer {
      margin-top: 0;
      border-top: 1px solid @white1;
      padding: 16px;
      .ant-btn-link{
        color: @grayshade;
      }
      > button {
        .fw-500();
      }
    }
  }
}

@primary-color: #6E3BD1;@page-header-heading-title: 24px;@btn-border-radius-base: 4px;@btn-border-radius-sm: 4px;