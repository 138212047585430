@import 'styles/variable.less';

.aero-report-main {
  background-color: @white;
  .report-content-header {
    height: 58px;
    padding: 16px 0 16px 16px;
    align-items: center;
    background-color: @headerBackClr;
    > div:first-child {
      .flexAlignCenter();
      .pageheader-title {
        .fs-14();
        .fw-500();
        line-height: 18px;
        padding-bottom: 2px;
        color: @primary;
      }
    }
    > div:last-child {
      color: @primary;
      .fs-16();
      .fw-600();
      line-height: 21.86px;
      text-transform: uppercase;
    }
  }
  .report-content-main {
    height: calc(100% - 58px - 16px);
    .flexJustifyCenter();
    overflow: auto;
    scroll-behavior: smooth;
    .scrollbarW5();
    background-color: @white;
    padding: 16px;
    margin-bottom: 16px;
    > div {
      .scrollbarW5();
      .report-code-title {
        line-height: 52px;
        .flexJustifyAlignCenter();
        background-color: #fbfbfb;
        border-radius: 8px;
        .fs-18();
        .fw-700();
        color: @primary;
      }
      .report-info-cont-card {
        margin-top: 16px;
        justify-content: space-between;
        > div {
          background-color: #fbfbfb;
          padding: 12px;
          border-radius: 4px;
          > div:last-child {
            padding-left: 48px;
            color: @typocolor;
            .fs-16();
            .fw-700();
            line-height: 24px;
          }
        }
      }
      .report-info-content {
        .border-2();
        border-radius: 8px;
        margin-top: 16px;
        .report-info-title {
          padding: 12px;
          color: @primary;
          .fs-16();
          .fw-700();
          line-height: 20px;
        }
        .report-info-cont {
          padding: 12px;
          .table-content-card {
            padding: 5px 0;
            border: 0;
          }
        }
      }
      > div:last-child {
        margin-top: 16px;
        .flexDirectionColumn();
        gap: 16px;
        .report-code-content {
          .border-2();
          border-radius: 8px;
          .report-content-title {
            padding: 12px;
            .flexAlignCenter();
            .borderBottom();
            justify-content: space-between;
            > span {
              .fs-16();
              .fw-700();
              line-height: 20px;
              color: @primary;
            }
            > button {
              padding: 0;
              width: 20px;
              height: 20px;
              background-color: unset;
            }
          }
          .report-content-data {
            padding: 12px;
            padding-top: 0;
            .data-sources-tool {
              padding-top: 12px;
              .reference-content {
                min-height: 228px;
                > div:nth-child(2) {
                  > div {
                    height: 100%;
                    border-color: @borderclr;
                    margin: 0 12px;
                  }
                }
                > div:first-child,
                > div:last-child {
                  > ul {
                    padding-left: 48px;
                    list-style: none;
                    > li {
                      .fs-14();
                      .fw-500();
                      line-height: 26px;
                    }
                  }
                }
              }
              .obs-terrain-content {
                // .terrain-table {
                //   min-height: 281px;
                // }
                .table-note {
                  .fs-14();
                  .fw-500();
                  line-height: 20px;
                  color: @grayshade;
                }
              }
            }
            .procedure-detail-content {
              padding-top: 12px;
              .flexDirectionColumn();
              gap: 16px;
              .procedure-type-list {
                background-color: #fbfbfb;
                padding: 4px 12px;
                .flexDirectionColumn();
                gap: 6px;
                > span {
                  .fs-12();
                  .fw-400();
                  line-height: 16.39px;
                  color: @secondary;
                }
                .type-list {
                  .flexAlignCenter();
                  gap: 70px;
                  > div {
                    .list-title {
                      text-transform: uppercase;
                      .font1460019();
                      color: @typocolor;
                    }
                  }
                }
              }
              .type-data-list {
                gap: 16px;
                @media print {
                  gap: 16px;
                }
              }
              .procedure-note {
                color: @grayshade;
                .fs-14();
                .fw-500();
                line-height: 19.12px;
              }
            }
            .general-data-content {
              gap: 16px;
              .flexDirectionColumn();
              &:first-child {
                padding-top: 12px;
              }
              .type-data-list {
                gap: 24px;
              }
            }
            .ifp-design-content {
              margin-top: 12px;
              border-radius: 4px;
            }
            .plan-profile-content {
              margin-top: 16px;
              .flexDirectionColumn();
              gap: 16px;
              // .plan-view-note {
              //   border: 0.5px solid @borderclr;
              // }
              // .procedure-instruct {
              //   border: 0.5px solid @borderclr;
              // }
            }
            .minsec-alt-content {
              margin-top: 12px;
              border: 0.5px solid @borderclr;
              border-radius: 4px;
            }
            .sid-route-content {
              margin-top: 12px;
              border: 0.5px solid @borderclr;
              border-radius: 4px;
              .sid-route-table {
                th.table-col-data {
                  background-color: @headerBackClr !important;
                  padding: 8px 15px;
                  .fs-14();
                  .fw-500();
                  color: @secondary;
                  line-height: 20px;
                  &:first-child::before {
                    display: none;
                  }
                }
                .sid-title {
                  .font1460019();
                  color: @secondary;
                }
                .table-sid-row-data {
                  .table-col-data {
                    padding: 8px 15px;
                    border-bottom: 0.5px dashed @borderclr;
                    &:first-child {
                      border-right: 0.5px dashed @borderclr;
                    }
                    &:last-child {
                      text-align: start !important;
                    }
                  }
                  &:last-child {
                    .table-col-data {
                      border-bottom: 0;
                    }
                  }
                }
              }
            }
            .coding-table-content {
              margin-top: 16px;
            }
          }
        }
      }
    }
  }
}
.obstacle-table {
  margin-top: 12px;
  margin-bottom: 5px;
  .border-2();
  border-radius: 4px;
  padding-bottom: 1px;
  .table-title {
    padding: 12px;
    .report-title {
      .fw-600();
      text-transform: none;
    }
  }
  .table-col-data {
    height: 50px;
    background-color: @headerBackClr !important;
    padding: 6px 15px !important;
    .fs-14();
    .fw-500() !important;
    color: @secondary;
    line-height: 20px;
    text-transform: uppercase;
  }
  .table-row-data {
    .table-col-data {
      height: 50px;
      background-color: unset !important;
      padding: 8px 15px;
      border-bottom: 0.5px dashed @borderclr;
      border-right: 0.5px dashed @borderclr;
      .fs-14();
      .fw-500() !important;
      color: @typocolor;
      line-height: 19.12px;
      &:last-child {
        border-right: 0;
      }
    }
    .coding-col{
      padding-left: 10px !important;
      padding-right: 10px !important;
    }
    &:last-child {
      .table-col-data {
        border-bottom: 0;
      }
    }
  }
  .table-tol-col-data {
    border-bottom: 0.5px dashed @borderclr !important;
  }
  .table-tol-row-data td {
    border-bottom: 0.5px dashed @borderclr !important;
  }
  .bd-right {
    border-right: 0.5px dashed @borderclr !important;
  }

  .remark-row {
    border-bottom: 0 !important;
    &:first-child {
      text-align: center;
    }
  }
  .remark-row-data {
    border-right: 0 !important;
    padding: 8px 15px;
  }
  .add-new-row {
    .flexAlignCenter();
    gap: 8px;
    > span {
      .fs-14();
      .fw-500();
      color: @primary;
    }
    > svg,
    > span {
      cursor: pointer;
    }
  }
  .delete-row {
    > svg {
      cursor: pointer;
    }
  }
  .ant-table-content {
    width: 100%;
    overflow: auto;
    .scrollbarW5();
    > table {
      border: 0;
    }
  }
}
.obstacle-map {
  height: 450px;
  width: 100%;
  .obs-map {
    height: 100%;
    width: 100%;
  }
}

@primary-color: #6E3BD1;@page-header-heading-title: 24px;@btn-border-radius-base: 4px;@btn-border-radius-sm: 4px;