@import 'styles/variable.less';

.user-detail-main {
  .user-detail-avatar {
    border: 1px solid @secondary;
    background-color: @white;
    > span {
      color: #696b72;
      .fs-10();
      .fw-500();
      line-height: 20px !important;
    }
  }
  .user-label {
    padding-left: 8px;
    color: #696b72;
    .fs-14();
    .fw-500();
    line-height: normal;
  }
}

@primary-color: #6E3BD1;@page-header-heading-title: 24px;@btn-border-radius-base: 4px;@btn-border-radius-sm: 4px;