.ql-toolbar {
  background: #FBFBFB;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  border: 0 !important;
  padding: 11px 8px !important;
  border-bottom: 0.5px solid #c7ccc7 !important;
}
.ql-container {
  min-height: 170px;
  background: #FBFBFB;
  border: 0 !important;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}
.ql-editor {
  padding: 12px !important;
  >ol, >ul{
    padding-left: 0 !important;
  }
}

@primary-color: #6E3BD1;@page-header-heading-title: 24px;@btn-border-radius-base: 4px;@btn-border-radius-sm: 4px;