@import 'styles/variable.less';
@import 'styles/main.less';

.design-table-content {
  border-radius: 0 4px 4px;
  border: 1px solid @primary;
  background: @white;
  height: calc(100% - 128px);
  table {
    thead {
      tr {
        th {
          &:first-child {
            padding-left: 40px;
          }
        }
      }
    }
  }
  .ant-table-placeholder {
    height: calc(100vh - 270px);
    .ant-table-cell {
      border-bottom: 0 !important;
    }
  }
  .ant-table-row-expand-icon {
    display: none !important;
  }
}
.view-inactive-btn {
  opacity: 0.5;
}
.tooltip-title {
  .fw-500();
  .fs-12();
  line-height: normal;
  color: @white;
}
.list-table-content {
  height: calc(100% - 88px);
}
.design-table-tab {
  height: 40px;
  > div {
    height: inherit;
    > button {
      height: inherit;
      border: 0;
      .fs-16();
      .fw-700();
      letter-spacing: 0.12px;
      border-start-start-radius: 4px !important;
      border-start-end-radius: 4px !important;
      padding-left: 25px;
      padding-right: 25px;
    }
    .btn-all {
      background-color: #fafafa;
      color: #95969d;
    }
    .active-btn {
      border-radius: 4px 4px 0 0;
      background-color: rgba(110, 59, 209, 0.15);
      color: @primary;
      border-bottom: 3px solid @primary;
    }
    .btn-complete {
      border-end-end-radius: 0px !important;
    }
  }
}

@primary-color: #6E3BD1;@page-header-heading-title: 24px;@btn-border-radius-base: 4px;@btn-border-radius-sm: 4px;